import * as ko from "knockout";
import {Modal} from "Core/Common/Modal";
import {Event} from "Core/Common/Event";
import {Timer} from "Core/Common/Timer";
import {LABELS} from "Core/Components/Translation/Locales";

import ConfirmationDialogTemplate from 'Core/Components/Dialogs/DecisionDialog/Templates/View.html';
import { Brand } from "../../../Common/Brand";

ko.templates["Core/Components/Dialogs/DecisionDialog/Templates/View"] = ConfirmationDialogTemplate;

const TEMPLATES = {
	VIEW: "Core/Components/Dialogs/DecisionDialog/Templates/View"
};

export const EVENTS = {
	CONFIRM_SELECTED: "ConfirmSelected",
	DISCARD_SELECTED: "DiscardSelected",
	CANCEL_SELECTED: "CancelSelected"
};

export enum Types {
	Question,
	Warning,
	Error
}

export interface IDecisionDialogOptions {
	Text: string;
	Type: Types;
	Width?: number;
	MinHeight?: number;
	TextConfirm?: string;
	TextDecline?: string;
	TextCancel?: string;
	Timeout?: number;
	ModalClass?: number;
}

export class DecisionDialog extends Event {
	private _modal: Modal;
	private _text: KnockoutObservable<string>;
	private _textConfirm: KnockoutObservable<string>;
	private _textDecline: KnockoutObservable<string>;
	private _textCancel: KnockoutObservable<string>;
	private _options: IDecisionDialogOptions;
	private _timer: Timer;
	private _modalClass: string;

	constructor(options: IDecisionDialogOptions) {
		super();
		this._options = options;
		this.Init();

		if (options.Timeout > 0) {
			this._timer = new Timer(options.Timeout);
		}
	}

	get IsQuestion() {
		return this._options.Type === Types.Question;
	}

	get IsWarning() {
		return this._options.Type === Types.Warning;
	}

	get IsError() {
		return this._options.Type === Types.Error;
	}

	private get IsTimerOn() {
		return this._timer != null;
	}

	private Init() {
		this._text = ko.observable(this._options.Text);
		this._textConfirm = ko.observable(this._options.TextConfirm || LABELS.YES);
		this._textDecline = ko.observable(this._options.TextDecline || LABELS.NO);
		this._textCancel = ko.observable(this._options.TextCancel || LABELS.CANCEL);
		this._timer = null;
		this._modalClass = '';
	}

	private InitModal() {
		this._modal = new Modal({
			width: this._options.Width || '405',
			minHeight: this._options.MinHeight || '200',
			closeButton: false,
			closeOnEsc: false,
			addClass: this._options.ModalClass === undefined ? '' : this._options.ModalClass + "",
			appendTo: 'body:first'
		}, false);
	}

	AddTimer(seconds: number) {
		this._timer = new Timer(seconds);
	}

	Show() {
		this.InitModal();
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
		if (this.IsTimerOn) {
			this._timer.Start();
		}
	}

	GetTemplateName() {
		return TEMPLATES.VIEW;
	}

	OnYesClick() {
		this.Trigger(EVENTS.CONFIRM_SELECTED);
		this._modal.Close();
	}

	OnNoClick() {
		this.Trigger(EVENTS.DISCARD_SELECTED);
		this._modal.Close();
	}

	OnCancelClick() {
		this.Trigger(EVENTS.CANCEL_SELECTED);
		this._modal.Close();
	}

	Close() {
		this._modal.Close();
		if (this._timer) {
			this._timer.Stop();
		}
	}

	private AfterRender() {
		this._modal.Show();
	}

	get LogoUrl(){
		return `/img/Custom/App/${Brand.Name}/official-main-logo-white.jpg`;
	}
}