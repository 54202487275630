import * as ko from 'knockout';
import * as _ from 'underscore';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {CONFIRMATIONS, NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";

import {EventBus, IEventArgs} from "Core/Common/EventBus/EventBus";

import {
    ConfirmationDialog, EVENTS,
    EVENTS as CONFIRMATION_EVENTS,
    Types
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import {IProductConfigurationPageParams, ProductConfiguratorPage} from "../ProductConfiguratorPage";

import {NavigationStack} from '../../Stores/NavigationStack/NavigationStack';
import {NavigationItemTypes} from '../../Stores/NavigationStack/NavigationItem';

import {StartPageStore} from "./Stores/StartPageStore";
import {StartPageMappings} from "./Mappings/StartPageMappings";

import {ProductResponse} from "./Stores/Models/ProductResponse";

import {ViewModel} from "./Models/ViewModel";
import {Product} from "./Models/Product";

import {ProductDto} from "Core/Components/Controls/ProductConfigurator/Models/ProductDto";
import {SaveConfigurationDataDto} from 'Core/Components/Controls/ProductConfigurator/Pages/StartPage/Stores/Params/SaveConfigurationDataDto';
import {SaveAllConfigurationDataDto} from 'Core/Components/Controls/ProductConfigurator/Pages/StartPage/Stores/Params/SaveAllConfigurationDataDto';

import {SaveConfigurationDataEventArgs, StartPageEvents} from "./Events/StartPageEvents";

import Template from './Templates/Template.html';
import NewProductTemplate from './Templates/NewProduct.html';
import {ConfiguratorLevels} from 'Core/Components/Controls/ProductConfigurator/ConfiguratorLevels';
import {ConfigurationDataResponse} from './Stores/Models/ConfigurationDataResponse';

interface IStartPageParams extends IProductConfigurationPageParams {
    ImageSizes: IStartPageImageSizes;
    DescriptionFieldId?: number;
    ControlId: number;
    Annotations: any;
    HasLevels: boolean;
}

export interface IStartPageImageSizes {
    OrderedProduct: number
}

export class StartPage extends ProductConfiguratorPage<IStartPageParams> {
    _labels = LABELS;
    private _store: StartPageStore;
    private _mappings: StartPageMappings;
    private _imageSize: number;
    private _imageSizeClassName: string;

    private _viewModel: ViewModel;
    protected CanCreateAndCopy: KnockoutComputed<boolean>;
    protected ShowConfigurationTabs: KnockoutComputed<boolean>;
    protected ShowSaveAndCloseButton: KnockoutComputed<boolean>;
    protected ShowCopyButton: KnockoutComputed<boolean>;

    constructor(params: IStartPageParams, navigationStack: NavigationStack, eventBus: EventBus) {
        super(params, navigationStack, eventBus);

        this._imageSize = params.ImageSizes.OrderedProduct;
        this._imageSizeClassName = null;
        this._store = new StartPageStore(params.OrderEntityId, params.ProductsEntityId, params.DescriptionFieldId);
        this._mappings = new StartPageMappings();

        this._viewModel = new ViewModel(eventBus);

        this.CanCreateAndCopy = ko.computed(() => !this.RolesImplemented || this.Level1Active);
        this.ShowConfigurationTabs = ko.computed(() => this.RolesImplemented && this.Level2Active);
        this._viewModel.CanCreateAndCopy = ko.computed(() => this.CanCreateAndCopy());
        this.ShowCopyButton = ko.computed(() => this.CanCreateAndCopy() && params.HasLevels);
        this._viewModel.ShowCopyButton = ko.computed(() => this.ShowCopyButton());

        this.ShowSaveAndCloseButton = ko.computed(() => this.ShowConfigurationTabs() && this._viewModel
            && _.any(this._viewModel.Products(), product => _.contains(product.Levels, ConfiguratorLevels.Level2)));

        this.BindEvents();
    }

    public CloseModal() {
        this.DispatchEvent(StartPageEvents.ClosePopup);
    }

    get CloseMessage() {
        return this.ShowSaveAndCloseButton() ? LABELS.CANCEL : LABELS.CLOSE_LABEL;
    }

    get Level1Active() {
        return this.UserHasLevelRole(ConfiguratorLevels.Level1) && this.ActiveConfigurationRole().Id == this.params.ConfigurationRoles.find(role => role.Level == ConfiguratorLevels.Level1).Id;
    }

    get Level2Active() {
        return this.UserHasLevelRole(ConfiguratorLevels.Level2) && this.ActiveConfigurationRole().Id == this.params.ConfigurationRoles.find(role => role.Level == ConfiguratorLevels.Level2).Id;
    }

    GetTemplate() {
        return Template;
    }

    GetNewProductTemplate() {
        return NewProductTemplate;
    }

    Dispose() {
        this._viewModel.Dispose();
        super.Dispose();
    }

    get ImageSizeClassName(): string {
        return this.GetImageSizeClassName();
    }

    GetImageSizeClassName() {
        switch (this._imageSize) {
            case 60:
                return this._imageSizeClassName = 'imageSize-60';
            case 70:
                return this._imageSizeClassName = 'imageSize-70';
            case 80:
                return this._imageSizeClassName = 'imageSize-80';
            case 90:
                return this._imageSizeClassName = 'imageSize-90';
            default:
                return this._imageSizeClassName = 'imageSize-100';
        }
    }

    Refresh() {
        BlockUI.Block();

        const payload = {
            OrderEntityId: this.params.OrderEntityId,
            ProductEntityId: this.params.ProductsEntityId,
            OrderId: this.params.OrderId
        };

        return this._store.GetConfiguredProducts(payload)
            .then(configuredProductsDto => {
                this.UpdateNavigationStack(payload);
                this.FillViewModel(configuredProductsDto);
                BlockUI.Unblock();
            }).fail(err => {
                BlockUI.Unblock();
                new Notifier().Failed(err.message);
            });
    }

    OnAddNewProductClicked() {
        this.DispatchEvent(StartPageEvents.AddNewProduct);
    }

    private UpdateNavigationStack(payload: any) {
        this.navigationStack.Upsert(LABELS.ORDERED_PRODUCTS, NavigationItemTypes.StartPage, payload);
    }

    private BindEvents() {
        this.ActiveConfigurationRole.subscribe((newRole) => {
            if (this.Level2Active) {
                this.SelectFirstTab();
            }

            _.each(this._viewModel.Products(), product =>
                product.ActiveConfigurationLevel = newRole && newRole.Level);
        });

        this.HandleEvent(StartPageEvents.Selected)
            .Using((eventArgs: IEventArgs<Product, Product>) => this.SelectProduct(eventArgs.Data))
            .Always();

        this.HandleEvent(StartPageEvents.Copied)
            .Using((eventArgs: IEventArgs<Product, Product>) => this.ConfirmProductCopy(eventArgs.Data))
            .Always();

        this.HandleEvent(StartPageEvents.Removed)
            .Using((eventArgs: IEventArgs<Product, Product>) => this.ConfirmProductRemove(eventArgs.Data))
            .Always();

        this.HandleEvent(StartPageEvents.TabSelected)
            .Using(eventArgs => this.OnTabSelected(eventArgs.Source))
            .Always();

        this.HandleEvent<SaveConfigurationDataEventArgs>(StartPageEvents.SaveConfigurationData)
            .Using(eventArgs => this.SaveConfigurationData(eventArgs.Source, eventArgs.Data))
            .Always();
    }

    private FillViewModel(configuredProductsDto: ProductResponse[]) {
        const products = this._mappings.MapToProducts(configuredProductsDto, this.params.OrderEntityId, this.params.ControlId, this.params.ProductsEntityId, this.params.Annotations,
            (configuredProductsDto, product) => product.AssignEventBus(this.EventBus));

        const showConfigurationDescription = this.params.DescriptionFieldId > 0;
        products.forEach(product => {
            product.ImageSize = this.params.ImageSizes.OrderedProduct;
            product.ShowConfigurationDescription = showConfigurationDescription;
            product.ActiveConfigurationLevel = this.ActiveConfigurationRole() && this.ActiveConfigurationRole().Level;

            if (this.RolesImplemented) {
                product.readOnly = ko.computed(() => {
                    return product.Levels.indexOf(this.ActiveConfigurationRole().Level) == -1
                });
            }
        });
        this._viewModel.Products(products);

        if (this.Level2Active) {
            this.SelectFirstTab();
        }
    }

    private FillConfigurationData(product: Product, configurationDataDto: ConfigurationDataResponse) {
        product.SetMemo(configurationDataDto.Memo);
        product.SetGalleryImages(configurationDataDto.ImageGalleryExists, configurationDataDto.Images);
    }

    private SelectTab(product: Product) {
        product.SelectTab();
    }

    private OnTabSelected(product: Product) {
        this.UnSelectOtherTabs(product);

        if (!product.HookLoaded) {
            product.HookLoaded = true;

            this._store.GetConfigurationData(product.ConfigurationId)
                .then(configurationData => {
                    this.FillConfigurationData(product, configurationData);
                });
        }
    }

    private UnSelectOtherTabs(product: Product) {
        const otherProducts = this._viewModel.Products().filter(p => p !== product);
        otherProducts.forEach(p => p.UnSelectTab());
    }

    private SelectFirstTab() {
        const firstProduct = _.first(this._viewModel.Products());

        if (firstProduct) {
            this.SelectTab(firstProduct);
        }
    }

    private SelectProduct(product: Product) {
        const productDto = this._mappings.MapToProductDto(product);
        this.DispatchEvent<ProductDto>(StartPageEvents.ConfiguredProductSelected, productDto);
    }

    private ConfirmProductCopy(product: Product) {
        const dialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.DO_YOU_WANT_TO_COPY,
            Type: Types.Question
        });

        dialog.On(CONFIRMATION_EVENTS.CONFIRM_SELECTED, this, () =>
            this.CopyProduct(product).then(() => this.Refresh()));

        dialog.Show();
    }

    private ConfirmProductRemove(product: Product) {
        const confirmationText = this.CanCreateAndCopy()
            ? CONFIRMATIONS.DO_YOU_WANT_TO_DELETE_CURRENT_CONFIGURATION
            : CONFIRMATIONS.DO_YOU_WANT_TO_DELETE_CURRENT_CONFIGURATION_LEVEL;

        const dialog = new ConfirmationDialog({
            Text: confirmationText,
            Type: Types.Question
        });

        dialog.On(CONFIRMATION_EVENTS.CONFIRM_SELECTED, this, () =>
            this.RemoveProduct(product).then(() => this.Refresh()));

        dialog.Show();
    }

    private CopyProduct(product: Product) {
        const promise = this._store.CopyConfiguredProduct(product.ConfigurationId);

        promise
            .then(() => new Notifier().Success(NOTIFICATIONS.OBJECT_HAS_BEEN_COPIED.replace('{object}', product.Name)))
            .fail(err => new Notifier().Failed(err.message));

        return promise;
    }

    private RemoveProduct(product: Product) {
        let promise;
        if (this.CanCreateAndCopy()) {
            promise = this._store.RemoveConfiguredProduct(product.ConfigurationId);

        } else {
            promise = this._store.RemoveConfiguredProductByLevel(product.ConfigurationId, this.ActiveConfigurationRole().Level);
        }
        promise
            .then(() => {
                new Notifier().Success(NOTIFICATIONS.OBJECT_HAS_BEEN_REMOVED.replace('{object}', product.Name));
                if (!this.CanCreateAndCopy()) {
                    product.Levels.splice(product.Levels.indexOf(this.ActiveConfigurationRole().Level), 1);
                }
            })
            .fail(err => new Notifier(err.message));

        return promise;
    }

    private UserHasLevelRole(level: string) {
        return this.params.ConfigurationRoles.find(role => role.Level == level) != null;
    }

    private SaveConfigurationData(product: Product, configurationData: SaveConfigurationDataEventArgs) {
        const params = new SaveConfigurationDataDto(product.ConfigurationId,
            configurationData.Memo,
            configurationData.AddedImageAttachments,
            configurationData.RemovedImageIds,
            configurationData.UpdatedAnnotations);

        BlockUI.Block();

        this._store.SaveConfigurationData(params)
            .then(() => {
                new Notifier().Success(NOTIFICATIONS.DATA_SAVED);

                this._store.GetConfigurationData(product.ConfigurationId)
                    .always(() => {
                        BlockUI.Unblock();
                    })
                    .then(configurationData => {
                        this.FillConfigurationData(product, configurationData);
                    });
            })
            .fail(err => {
                BlockUI.Unblock();
                new Notifier().Failed(err.message);
            });
    }

    public SaveAndCloseModal() {
        const productWithConfigurationDataChanges = this._viewModel && _.filter(this._viewModel.Products(), product => product.ConfigurationDataHasChanges);

        if (!_.any(productWithConfigurationDataChanges)) {
            this.DispatchEvent(StartPageEvents.ClosePopup);
            return;
        }

        let saveConfigurationDataDtos = [];

        _.each(productWithConfigurationDataChanges, product => {
            const configurationDataArgs = product.GetSaveConfigurationDataArgs();

            saveConfigurationDataDtos.push(new SaveConfigurationDataDto(
                product.ConfigurationId,
                configurationDataArgs.Memo,
                configurationDataArgs.AddedImageAttachments,
                configurationDataArgs.RemovedImageIds,
                configurationDataArgs.UpdatedAnnotations));
        });

        BlockUI.Block();

        this._store.SaveAllConfigurationData(new SaveAllConfigurationDataDto(saveConfigurationDataDtos))
            .always(() => {
                BlockUI.Unblock();
                this.DispatchEvent(StartPageEvents.ClosePopup);
            })
            .fail(err => {
                new Notifier().Failed(err.message);
            });
    }
}