import * as ko from "knockout";
import {Modal} from "Core/Common/Modal";
import {Event} from "Core/Common/Event";
import {LABELS, CONFIRMATIONS} from "Core/Components/Translation/Locales";
import {DELETION_MODES} from "Core/Constant";

import DeletionModeDialogTemplate from 'Core/Components/Dialogs/DeletionModeDialog/Templates/View.html';
import { Brand } from "../../../Common/Brand";

ko.templates["Core/Components/Dialogs/DeletionModeDialog/Templates/View"] = DeletionModeDialogTemplate;

const TEMPLATES = {
	VIEW: "Core/Components/Dialogs/DeletionModeDialog/Templates/View"
};

export const EVENTS = {
	DELETE_SELECTED: "DeleteSelected",
	DISABLE_SELECTED: "DisableSelected",
	DISCARD_SELECTED: "DiscardSelected"
};

export interface IDeletionModeDialogOptions {
	DeletionMode: number;
	HasCustomLifeStatus: boolean;
	Width?: number;
	MinHeight?: number;
	TextConfirm?: string;
	TextDecline?: string;
	TextSkip?: string;
	ModalClass?: string;
}

export class DeletionModeDialog extends Event {
	private _modal: Modal;
	private _text: KnockoutObservable<string>;
	private _options: IDeletionModeDialogOptions;
	private _modalClass: string;
	private _labels = LABELS;

	constructor(options: IDeletionModeDialogOptions) {
		super();
		this._options = options;
		this.Init();
	}

	get IsAsk() {
		return this._options.DeletionMode === DELETION_MODES.ASK.Id;
	}

	get IsPreserve() {
		return this._options.DeletionMode === DELETION_MODES.PRESERVE.Id;
	}

	get IsDelete() {
		return this._options.DeletionMode === DELETION_MODES.DELETE.Id;
	}

	get HasCustomLifeStatus() {
		return this._options.HasCustomLifeStatus;
	}

	private Init() {
		const confirmationMessage = this.IsPreserve
			? CONFIRMATIONS.DELETION_NOT_ALLOWED_DUE_TO_EXISTING_RELATED_RECORDS
			: CONFIRMATIONS.ALL_RELATED_DATA_WILL_BE_LOST_CONTINUE;
		this._text = ko.observable(confirmationMessage);

		this._modalClass = '';
	}

	private InitModal() {
		this._modal = new Modal({
			width: this._options.Width || '405',
			minHeight: this._options.MinHeight || '200',
			closeButton: false,
			closeOnEsc: false,
			addClass: this._options.ModalClass === undefined ? '' : this._options.ModalClass + "",
			appendTo: 'body:first'
		}, false);
	}

	Show() {
		this.InitModal();
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
	}

	GetTemplateName() {
		return TEMPLATES.VIEW;
	}

	OnDeleteClick() {
		this.Close();
		this.Trigger(EVENTS.DELETE_SELECTED);
	}

	OnDisableClick() {
		if (this.HasCustomLifeStatus) {
			return;
		}

		this.Close();
		this.Trigger(EVENTS.DISABLE_SELECTED);
	}

	OnCancelClick() {
		this.Close();
		this.Trigger(EVENTS.DISCARD_SELECTED);
	}

	Close() {
		this._modal.Close();
	}

	private AfterRender() {
		this._modal.Show();
	}
	get LogoUrl(){
		return `/img/Custom/App/${Brand.Name}/official-main-logo-white.jpg`;
	}
}