export var Config = {
    spheeres: {
        Title: 'Spheeres.com'
    },

    corsearch: {
        Title: 'Corsearch.com'
    }
}

export class Brand {
    public static InitTitle(){
        document.title = Config[this.Name].Title;
    }

    public static get Name(){
        return window['brand'] || 'spheeres';
    }
}