import '../css/application.scss';

import {$, moment} from 'Index';

import {NetworkMonitor} from 'Core/Common/NetworkMonitor';
import {MenuManager} from "MenuManager/MenuManager";
import {EventTracker} from "Core/Common/EventTracker";
import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';
import {UserManager} from "User/UserManager";
import {Metronic} from 'metronic';
import {Layout} from 'metronicLayout';
import {QuickSidebar} from 'metronicQuickSidebar';
import {Demo} from 'metronicDemo';
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {UserMenu} from 'MenuManager/UserMenu/UserMenu'
import {SecurityProfileMenu} from "MenuManager/SecurityProfileMenu/SecurityProfileMenu";
import {LanguageMenu} from "./MenuManager/LanguageMenu/LanguageMenu";
import {MainMenuButton} from "./MenuManager/MainMenuButton/MainMenuButton";
import {MainMenuButtonResponsive} from "./MenuManager/MainMenuButtonResponsive/MainMenuButtonResponsive";
import {Breadcrumbs} from "MenuManager/Breadcrumbs/Breadcrumbs";
import {EVENTS as USER_MENU_EVENTS} from 'MenuManager/UserMenu/Events';
import {P} from 'Core/Common/Promise';
import {CookieManager} from "Core/Common/CookieManager";
import {ScreenShotMaker as ScreenShotMaker} from "Core/Components/ScreenShot/ScreenShotMaker";
import {ReportIssueModal} from "Core/Components/ReportIssueModal/ReportIssueModal";
import {ErrorHolder} from "Core/ErrorHolder/ErrorHolder";
import {Header} from "MenuManager/Header/Header";
import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";
import {TranslationManager} from './Core/Components/Translation/TranslationManager';
import {FormatConverter} from 'FormatEditor/FormatConverter';
import {SignalRNotificationManager} from "./Core/Components/SignalR/SignalRNotificationManager";
import {CLIPBOARD_IMAGES_TOPIC} from "Core/Controls/Image/Image";
import { BlockUI } from "Core/Common/BlockUi";
import {TimersMenu} from "./MenuManager/TimersMenu/TimersMenu";
import { LockScreen } from 'LockScreen/LockScreen';
import {MobileChecker} from "Core/Common/MobileChecker";
import { startRecording } from './Session/SessionRecorder';
import { AssistantApi } from './DatabaseDesigner/Components/Assistant/AssistantApi';
import { Assistant, AssistantModes } from './DatabaseDesigner/Components/Assistant/Assistant';
import { LABELS } from './Core/Components/Translation/Locales';
import { Brand } from './Core/Common/Brand';

export class App {
    private static _menuManager: MenuManager;
    private static _mainMenuTargetId = 'mainMenu';
    private static _headerTargetId = 'header';
    private static _userMenuTargetId = 'user-menu-container';
    private static _securityProfileMenuTargetId = 'security-profile-menu-container';
    private static _languageMenuTargetId = 'language-menu-container';
    private static _assistantTargetId = 'assistant-btn';
    private static _allTimersTargetId = 'allTimers-container';
    private static _breadcrumbsTargetId = 'breadcrumbs-container';
    private static _mainMenuButtonTargetId = 'menu-toggler-sidebar-toggler';
    private static _mainMenuButtonResponsiveTargetId = 'menu-toggler-responsive-toggler';
    private static _header: Header;
    private static _userMenu: UserMenu;
    private static _securityProfileMenu: SecurityProfileMenu;
    private static _languageMenu: LanguageMenu;
    private static _timersMenu: TimersMenu;
    private static _breadcrumbs: Breadcrumbs;
    private static _screenShotMaker: ScreenShotMaker;
    private static _mainMenuButton: MainMenuButton;
    private static _mainMenuButtonResponsive: MainMenuButtonResponsive;
    private static _lockScreen: LockScreen;

    static Main() {
       

        
        Brand.InitTitle();

        NetworkMonitor.Init();
        CookieManager.Init();

        this._menuManager = MenuManager.Instance;
        this._menuManager.Bind(App._mainMenuTargetId);

        this.InitHeader(false);
        this.InitScreenShotMaker(false);
        this.ListenForPrintScreenPress();
        this.ListenForErrors();
        this.ListenForPasteEvent();
        this.InitUserMenu(false);
        this.InitSecurityProfileMenu(false);
        this.InitMainMenuButton(false);
        this.InitMainMenuButtonResponsive(false);
        this.HandleUnauthorizeRequest();
        this.TrackEvents();
        this.HideSidebar();

        const accessToken = CookieManager.GetAuthToken();
        const refreshToken = CookieManager.GetRefreshToken();
        const sbiDesigner = CookieManager.GetSBIDesigner();
        const resetPasswordToken = CookieManager.GetValue('ResetPasswordToken');

        if (resetPasswordToken) {
            this.InitMetronic();
            this._menuManager.RemoveOverlay();
            this._userMenu.ResetPassword();
        } else if (accessToken) {
            UserManager.Instance.SignIn({
                AccessToken: accessToken,
                RefreshToken: refreshToken,
                RefreshUserVars: false,
                SBIDesigner: sbiDesigner
            }).then(() => {               
                TranslationManager.Instance.LoadTranslations()
                    .then(() => {
                        TranslationManager.Instance.LoadLanguages()
                            .then(() => {
                                GlobalManager.Instance.LoadGlobals()
                                    .then(() => {

                                        if(UserManager.Instance.CurrentUser.IsLocked){
                                            PubSub.publish('SESSION_LOCKED', null);
                                            return;
                                        }
                                        this.InitLockScreen();
                                        this.ShowSidebar();
                                        this.InitMetronic();
                                        this.InitHeader(true);
                                        this.InitScreenShotMaker(true);
                                        this.InitUserMenu(true);
                                        this.InitSecurityProfileMenu(true);
                                        this.InitMainMenuButton(true);
                                        this.InitMainMenuButtonResponsive(true);
                                        this.IniLanguageMenu();
                                        this.InitAssistant();
                                        this.InitAllTimers();
                                        this.InitBreadcrumbs();
                                        this._menuManager.IsAuthenticated(true);
                                        this.SetTitle(UserManager.Instance.CurrentUser.LicenseName);
                                        UserVarsManager.Instance.UservarsLastSync();
                                        this._menuManager.RefreshMenuAreas(true);
                                        this._menuManager.RefreshStaticMenu();
                                        this.SetMomentlocale(FormatConverter.GetLocale());

                                        SignalRNotificationManager.Instance.Start();
                                    });
                            });
                    });

            }).fail(() => {
                this._menuManager.RemoveOverlay();
                this._menuManager.ShowGuestHomePage();
            })
        } else {
            this.InitMetronic();
            this._menuManager.RemoveOverlay();
            this._menuManager.ShowGuestHomePage();
        }

        let broadcastChannel = new BroadcastChannel('lock_channel');
        broadcastChannel.onmessage = (event) => {
            if(event.data == 'lock'){
                window.location.reload();
            }
        };
    }
    static HandleUnauthorizeRequest() {
        PubSub.subscribe('UNAUTHORIZE', (message, data) => {
            PubSub.unsubscribe('UNAUTHORIZE');
            this._userMenu.Trigger(USER_MENU_EVENTS.SIGN_OUT, {showLoginPopup: true});
        });
    }

    static InitHeader(isAuthenticated: boolean) {
        this._header = new Header();
        this._header.On('NAVIGATE_TO_HOME_PAGE', this, () => this._menuManager.NavigateToHomePage());
        this._header.Render(App._headerTargetId);
        this._header.IsAuthenticated(isAuthenticated);
    }

    static SetTitle(title = 'Spheeres.com') {
        document.title = title;
    }

    static BindUserMenuEvents() {
        this._userMenu.On(USER_MENU_EVENTS.SIGN_IN, this, (eventArgs: any) => {
            if (eventArgs.data && eventArgs.data.AuthToken) {
                this.ShowSidebar();
                $('#vieBody').empty();

                const globalManager = GlobalManager.Instance;
                BlockUI.Block();
                TranslationManager.Instance.LoadTranslations()
                    .then(() => {
                        this.SetTitle(UserManager.Instance.CurrentUser.LicenseName);
                        if (this._screenShotMaker) {
                            this._screenShotMaker.SetIsAuthenticated(true);
                        }
                        TranslationManager.Instance.LoadLanguages()
                            .then(() => {
                                globalManager.LoadGlobals()
                                    .then(() => {
                                        SignalRNotificationManager.Instance.Start();

                                        const menuManager = MenuManager.Instance;
                                        menuManager.IsAuthenticated(true);
                                        menuManager.RefreshMenuAreas();
                                        menuManager.RefreshStaticMenu();
                                        this.InitAssistant();
                                        this.IniLanguageMenu();
                                        this.InitAllTimers();
                                        this.InitBreadcrumbs();
                                        this.SetMomentlocale(FormatConverter.GetLocale());
                                        this._header.IsAuthenticated(true);
                                        this._userMenu.RefreshUser();
                                        this._userMenu.IsAuthenticated(true);
                                        this._securityProfileMenu.IsAuthenticated(true);
                                        this._mainMenuButton.IsAuthenticated(true);
                                        this._mainMenuButtonResponsive.IsAuthenticated(true);
                                        this.HandleUnauthorizeRequest();
                                        UserVarsManager.Instance.UservarsLastSync();
                                        Demo.InitUI();
                                    });
                            });
                    }).always(()=> {
                        BlockUI.Unblock();
                });
            }
        });

        this._userMenu.On(USER_MENU_EVENTS.SIGN_OUT, this, (eventArgs: any) => {
            this.HideSidebar();
            $('.page-content').addClass('guest');
            $('#viewBody').addClass('guest');
            $('.page-footer').addClass('guest');

            $('#assistant-container').hide();

            SignalRNotificationManager.Instance.Stop();
            const menuManager = MenuManager.Instance;
            menuManager.DeleteAreas();
            menuManager.ShowGuestHomePage();
            menuManager.IsAuthenticated(false);
            this._header.IsAuthenticated(false);
            this._userMenu.IsAuthenticated(false);
            this._securityProfileMenu.IsAuthenticated(false);
            this._mainMenuButton.IsAuthenticated(false);
            this._mainMenuButtonResponsive.IsAuthenticated(false);

            if (this._languageMenu) {
                this._languageMenu.IsAuthenticated(false);
            }

            if (this._timersMenu) {
                this._timersMenu.IsAuthenticated(false);
            }

            if (this._breadcrumbs) {
                this._breadcrumbs.IsAuthenticated(false);
            }

            UserManager.Instance.SignOut();
            UserVarsManager.Instance.SignOut();
            this.SetTitle();


            if (eventArgs) {
                if (eventArgs.data.showLoginPopup) {
                    this._userMenu.SetShowLoginPopup(false);
                    this._userMenu.Login();
                }
            }

            if (this._screenShotMaker) {
                this._screenShotMaker.SetIsAuthenticated(false);
            }
            Lockr.rm(LOCAL_STORAGE.FILE_DIR);

            this._menuManager.RemoveOverlay();
        });
    }

    private static InitUserMenu(isAuthenticated: boolean) {
        this._userMenu = new UserMenu();
        this._userMenu.Render(App._userMenuTargetId);
        this._userMenu.IsAuthenticated(isAuthenticated);
        this.BindUserMenuEvents();
    }

    private static InitSecurityProfileMenu(isAuthenticated: boolean) {
        this._securityProfileMenu = new SecurityProfileMenu(isAuthenticated);
        this._securityProfileMenu.On('CHANGE_LOGO_IMAGE', this, (eventArgs) => this._header.ChangeLogoImage(eventArgs.data.ProfileImage));
        this._securityProfileMenu.Render(App._securityProfileMenuTargetId);
    }

    private static InitAllTimers() {
        this._timersMenu = new TimersMenu();
        this._timersMenu.Render(App._allTimersTargetId);
    }

    private static IniLanguageMenu() {
        this._languageMenu = new LanguageMenu();
        this._languageMenu.Render(App._languageMenuTargetId);
    }

    private static InitAssistant() {
        if(UserManager.Instance.CurrentUser.IsShowAIAssistant){

            $(`#${this._assistantTargetId}`).attr('title', LABELS.CLICK_TO_START);

            $('#assistant-container').show();

            $(`#${this._assistantTargetId}`).on('click', ()=>{
                let assistant = new Assistant({ mode: AssistantModes.Help});
                assistant.Show();        
            });    
        }
    }

    private static InitBreadcrumbs() {
        this._breadcrumbs = new Breadcrumbs();
        this._breadcrumbs.Render(App._breadcrumbsTargetId);
    }

    private static InitMainMenuButton(isAuthenticated: boolean) {
        this._mainMenuButton = new MainMenuButton(isAuthenticated);
        this._mainMenuButton.Render(App._mainMenuButtonTargetId);
    }

    private static InitMainMenuButtonResponsive(isAuthenticated: boolean) {
        this._mainMenuButtonResponsive = new MainMenuButtonResponsive(isAuthenticated);
        this._mainMenuButtonResponsive.Render(App._mainMenuButtonResponsiveTargetId);
    }

    static InitScreenShotMaker(isAuthenticated: boolean) {
        // this._screenShotMaker = ScreenShotMaker.Instance;
        // this._screenShotMaker.SetIsAuthenticated(isAuthenticated);
    }

    static ListenForPrintScreenPress() {
        window.addEventListener(
            'keyup',
            event => {
                if (event.key === 'PrintScreen') {
                    ReportIssueModal.Open();
                }
            },
            false
        );
    }

    static ListenForErrors() {
        window.addEventListener(
            'error',
            event => {
                if (event.error) {
                    ErrorHolder.SaveError(event.error.stack);
                }
            },
            true
        );
    }

    private static Paste = (event) => {
        const images = Array.from(event.clipboardData.files).filter(file => (file as any).type.startsWith('image'));
        let isTextType = _.some(Array.from(event.clipboardData.items), item => (item as any).type.startsWith('text'));

        if (!isTextType && images.length > 0 && document.querySelectorAll('.image-control.EditScreenView').length > 0) {
            event.stopPropagation();

            PubSub.publish(CLIPBOARD_IMAGES_TOPIC, images);
        }
    }

    static ListenForPasteEvent() {
        document.body.addEventListener('paste', this.Paste,true);

        document.body.addEventListener(
            'keydown',
            event => {
                if (
                    event.key === 'Escape' &&
                    document.querySelectorAll('.image-control.EditScreenView.highlighted').length > 0
                ) {
                    event.stopPropagation();

                    PubSub.publish(CLIPBOARD_IMAGES_TOPIC, undefined);
                }
            },
            true
        );
    }

    static InitMetronic() {
        this.InitMobile();
        Metronic.init();
        Layout.init();
        QuickSidebar.init();
        Demo.init();
        Demo.handleTheme();
        Demo.InitUI();
        window.dispatchEvent(new Event('resize'));
        $(window).on('resize', () => {
            $('.queryBuilderDropdown').parent().removeClass('open');
        });
        $(window).on('scroll', () => {
            $('.queryBuilderDropdown').parent().removeClass('open');
        });
    }

    static HideSidebar() {
        $('.page-sidebar').width(0);
    }

    static ShowSidebar() {
        $('.page-sidebar').attr('style', null);
    }

    static InitMobile(){
        if (MobileChecker.IsMobile()){
            $(document.body).addClass('mobileMode');
        }
    }

    static TrackEvents() {
        EventTracker.Instance.ListenEvents();
    }


    static InitLockScreen(){
        setInterval(() => {
            let lockScreenHours = parseInt(GlobalManager.Instance.GetGlobalDescriptor(GLOBALS.LOCK_SCREEN_HOURS)?.Value);

            if(lockScreenHours > 0){
                const lockDate = Lockr.get<number>(LOCAL_STORAGE.LOCK_DATE);
                if(!lockDate){
                    Lockr.set(LOCAL_STORAGE.LOCK_DATE, new Date().getTime());
                    return;
                }

                var diff =  new Date().valueOf() - new Date(lockDate).valueOf();
                var diffInHours = diff/1000/60/60;
                if(diffInHours >= lockScreenHours){
                    this._lockScreen?.Close();
                    this._lockScreen = new LockScreen();
                    this._lockScreen.Lock();
                    Lockr.set(LOCAL_STORAGE.LOCK_DATE, new Date().getTime());
                }
            }
        }, 1000);
    }

    private static SetMomentlocale(culture: string): void {
        moment.locale(culture);
    }
}

App.Main();
