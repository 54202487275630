import * as ko from 'knockout';

import {P} from 'Core/Common/Promise';

import Template from './Template.html';
import iFrame from './iFrame.html';
import { Brand } from 'Core/Common/Brand';

ko.templates['Core/GuestHomePage/Template'] = Template;

export class GuestHomePage {
    private _container: HTMLElement;
    private _isReady: KnockoutObservable<boolean>;
    private _imageData: KnockoutObservable<string>;
    private _imageUrl: string;

    constructor() {
        this._isReady = ko.observable(false);
        this._imageData = ko.observable(null);
		this._imageUrl = this.GetImageUrl();
    }

    GetTemplateName() {
        return 'Core/GuestHomePage/Template';
    }

    GetIFrame() {
        return iFrame;
	}

    GetImageUrl() {
	    const subDomain = window.location.hostname.split('.')[0];
		var timestamp = +new Date;
		return `/Static/Images/${subDomain}/Guest.jpg?${timestamp}`;
    }

    AfterRender() {
        this.LoadImage().always(() => this._isReady(true));
    }

    RenderByTargetId(targetId: string) {
        this._container = document.getElementById(targetId);

        ko.cleanNode(this._container);
        ko.applyBindings(this, this._container);
    }

    Detach() {
        ko.cleanNode(this._container);
        this._container.innerHTML = '';
    }

    private LoadImage() {
        const deferred = P.defer();

        const img = new Image();

        img.onload = () => {
            this._imageData(this._imageUrl);
            deferred.resolve(null);
        };
        img.onerror = () => {
            this._imageData(null);
            deferred.reject(null);
        };

        img.src = this._imageUrl;

        return deferred.promise();
    }

    FormatUrl(){
        if(Brand.Name === 'spheeres'){
            return "https://spheeres.com";
        }

        if(Brand.Name === 'corsearch'){
            return "https://corsearch.com/lp/brand-protection-case-management";
        }
        return null;
    }
}