import * as ko from "knockout";
import {Modal} from "Core/Common/Modal";
import {Event} from "Core/Common/Event";
import { LABELS } from "Core/Components/Translation/Locales";
import { Notifier } from 'Core/Common/Notifier';



import PromptDialogTemplate from 'Core/Components/Dialogs/PromptDialog/Templates/View.html';
import { Brand } from "../../../Common/Brand";
ko.templates["Core/Components/Dialogs/PromptDialog/Templates/View"] = PromptDialogTemplate;

const TEMPLATES = {
	VIEW: 'Core/Components/Dialogs/PromptDialog/Templates/View'
};

const EVENTS = {
	SAVE: 'Save',
	CANCEL: 'Cancel'
};

export enum Types {
	Text,
	Password
}

export interface IPromptDialogOptions<TSelectorValue> {
	Type: Types;
	Label: string;
	Value?: string;
	Width?: number;
	MinHeight?: number;
	TextConfirm?: string;
	TextDecline?: string;
	Timeout?: number;
	ModalClass?: number;
	Required?: boolean;
	RequiredErrorMessage?: string;
	ShowNotification?: boolean;
	InvalidMessage?: string;
	CloseOnSave?: boolean;
	AddClass?: string;
}

export class PromptDialog<TSelectorValue> extends Event {
	private _modal: Modal;
	private _promptLabel: string;
	private _value: KnockoutObservable<string>;
	private _textConfirm: KnockoutObservable<string>;
	private _textDecline: KnockoutObservable<string>;
	private _invalid: KnockoutObservable<boolean>;
	private _required: boolean;
	private _options: IPromptDialogOptions<TSelectorValue>;
	private _modalClass: string;
	private _requiredErrorMessage: string;
	private _requiredInvalid: KnockoutObservable<boolean>;
	private _invalidMessage: KnockoutObservable<string>;
	private _showNotification: boolean;
	private _closeOnSave: boolean;

	constructor(options: IPromptDialogOptions<TSelectorValue>) {
		super();
		this.Init(options);
	}

	get IsTextPrompt() {
		return this._options.Type === Types.Text;
	}

	get IsPasswordPrompt() {
		return this._options.Type === Types.Password;
	}

	private Init(options: IPromptDialogOptions<TSelectorValue>) {
		this._options = options;
		this._promptLabel = options.Label;
		this._value = ko.observable(options.Value);
		this._invalid = ko.observable(false);
		this._requiredInvalid = ko.observable(false);
		this._required = this._options.Required || false;
		this._textConfirm = ko.observable(this._options.TextConfirm || LABELS.OK);
		this._textDecline = ko.observable(this._options.TextDecline || LABELS.CANCEL);
		this._modalClass = this._options.AddClass || '';
		this._requiredErrorMessage = this._options.RequiredErrorMessage;
		this._invalidMessage = ko.observable(this._options.InvalidMessage || '');
		this._showNotification = this._options.ShowNotification;
		this._closeOnSave = this._options.CloseOnSave;

		this._modal = new Modal({
			addClass: this._modalClass,
			width: this._options.Width || '405',
			minHeight: this._options.MinHeight || '400',
			closeButton: null
		}, false);
	}

	ExternalInvalidActionHandling(message: string) {
		this._invalidMessage(message);
		this._invalid(true);
	}

	Hide(triggerEvent: boolean = true) {
		if (triggerEvent){
			this.Trigger(EVENTS.CANCEL);
		}

		this._modal.Close();
	}


	Show() {
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
	}

	ChangeValue(data, event) {
		if (this._required && event.target.value.length > 0) {
			this._invalid(false);
			this._requiredInvalid(false);
		} else {
			this._requiredInvalid(true);
		}
	}

	OnSave() {
		if (!this._value() && this._required) {
			this._requiredInvalid(true);
			this._showNotification && new Notifier().Failed(this._requiredErrorMessage);
			return;
		}

		this.Trigger(EVENTS.SAVE, {value: this._value()});
		this._closeOnSave && this._modal.Close();
	}

	OnCancel() {
		this.Trigger(EVENTS.CANCEL);
		this._modal.Close();
	}

	private GetTemplateName(): string {
		return TEMPLATES.VIEW;
	}

	private AfterRender() {
		this._modal.Show();
	}

	get LogoUrl(){
		return `/img/Custom/App/${Brand.Name}/official-main-logo-white.jpg`;
	}
}