import * as ko from 'knockout'
import * as _ from 'underscore';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'
import {FollowUpRecordModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel";
import {FollowUpStore} from "Core/Controls/ButtonFollowUp/Stores/FollowUpStore";
import {FollowUpLifeStatuses} from "Core/Controls/ButtonFollowUp/FollowUpLifeStatuses";
import {LifeStatusesModel} from 'Core/Controls/ButtonFollowUp/Models/LifeStatusesModel';
import {FollowUpOptionModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpOptionModel";
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";

import {Notifier} from "Core/Common/Notifier";

import DesignTemplate from "Core/Controls/ButtonFollowUp/Templates/Design.html"
import EditTemplate from "Core/Controls/ButtonFollowUp/Templates/Edit.html"
import ViewTemplate from "Core/Controls/ButtonFollowUp/Templates/View.html"
import HelpViewTemplate from "Core/Controls/ButtonFollowUp/Templates/HelpView.html"
import LifeStatuses from "Core/Controls/ButtonFollowUp/Templates/LifeStatuses.html"
import {FollowUpParentRecordModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpParentRecordModel ";
import {ActionCheckList} from "../../Components/ActionCheckList/ActionCheckList";
import { LifeStatusesGeneralModel } from "./Models/LifeStatusesGeneralModel";
import { LifeStatusSimpleModel } from "Core/Controls/ButtonFollowUp/Models/LifestatusSimpleModel";


ko.templates["Core/Controls/ButtonFollowUp/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonFollowUp/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/ButtonFollowUp/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonFollowUp/Templates/Design"] = DesignTemplate;
ko.templates["Core/Controls/ButtonFollowUp/Templates/LifeStatuses"] = LifeStatuses;

export class ButtonFollowUp extends BaseControl {
    private _followUpRecord: FollowUpRecordModel;
    private _followUpLifeStatuses: FollowUpLifeStatuses;
    private _checkList: ActionCheckList;
    private _lifeStatuses: LifeStatusesModel;
    private _choosenLifeStatus: any;
    private _currentLifeStatus: any;
    private _disableReason: KnockoutObservable<string>;

    constructor(params: IControlParam) {
        super(params);
        this._disableReason = ko.observable(null);
        this._lifeStatuses = null;
        this._followUpLifeStatuses = null;
        this._choosenLifeStatus = null;
        this._currentLifeStatus = null;
        this.Init();
    }

    ApplyProperties(){}

    get IsEnabled() {
        return this._enabled();
    }

    private Init(): void {
        this._followUpRecord = null;
    }

    GetRecord(): FollowUpRecordModel {
        return this._followUpRecord;
    }

    SetRecord(value: FollowUpRecordModel) {
        this._followUpRecord = value;
    }

    Click() {
        if (this._help.IsHelpButtonPressed()) {
            this._help.ShowControlHelp(this);
        } else {
            var screen = this._form.GetScreen() as any;
            if (screen && (screen.IsConsultScreen || screen.ValidateData())) {
                if (this._followUpRecord && this._followUpRecord.LifeStatusId) {
                    screen.Trigger('FOLLOWUP');
                    return;
                }

                const actionSubject = screen.GetActionSubjectRecord();
                FollowUpStore.GetStatuses(actionSubject).then(result => this.ProcessingStatuses(result, screen));
            }
        }
    }

    ProcessingStatuses(result: LifeStatusesGeneralModel, screen: any) {
        if (result == null || !result.Child) {
            screen.Trigger('FOLLOWUP');
            return;
        }

        if (!this._followUpRecord) {
            this._followUpRecord = new FollowUpRecordModel();
        }

        if (result.Child.CurrentLifeStatus === null && result.Child.LifeStatuses.length === 1) {
            const firstStatus = result.Child.LifeStatuses[0];
            this._followUpRecord.LifeStatusId = firstStatus.Id;
            this._followUpRecord.LifeStatusSort = firstStatus.Sort;
            this._followUpRecord.LifeStatusName = firstStatus.Name;
            this._followUpRecord.LifeStatusNoActionNode = firstStatus.NoActionNode;
            this.GetForm().GetScreen().Trigger('FOLLOWUP');
            return;
        }

        this._followUpRecord.CurrentLifeStatus = result.Child.CurrentLifeStatus;

        this._lifeStatuses = result.Child;
        const showCheckLists = _.any(this._lifeStatuses.CheckLists, checkList => checkList.CheckItems.length > 0)
            && (!result.IsManyActions || result.Child.CurrentLifeStatus.MultipleActions);

        if (!result.IsManyActions && showCheckLists) {
            this.ShowCheckLists(result);
            return;
        }

        this._followUpLifeStatuses = new FollowUpLifeStatuses();
        if (result.IsManyActions && !result.Child.CurrentLifeStatus.MultipleActions) {
            result.Child.LifeStatuses = [result.Child.CurrentLifeStatus];
            this._followUpLifeStatuses.Caption = LABELS.EXIST_ACTIVE_ACTIONS;
        } else if (showCheckLists && result.Child.CurrentLifeStatus.MultipleActions) {
            this.ShowCheckLists(result);
            return;
        }

        const followUpOption = new FollowUpOptionModel();
        followUpOption.LifeStatuses = result.Child;

        let followUpParentOption: FollowUpOptionModel;
        if (result.Parent) {
            followUpParentOption = new FollowUpOptionModel();
            followUpParentOption.LifeStatuses = result.Parent;
        }

        if (this._lifeStatuses.LifeStatuses.length === 0) {
            new Notifier().Warning(NOTIFICATIONS.NEXT_STATUS_NOT_AVAILABLE);
        }


        this._followUpLifeStatuses.Init(followUpOption, followUpParentOption, null);
        this.ShowStandardFollowUp(result);
    }

    Close() {
        if (this._followUpLifeStatuses) {
            this._followUpLifeStatuses.Close();
        }

        if (this._checkList) {
            this._checkList.Close();
        }
    }

    SetValue(value: IControlValue): void {
        if (value.RecordSpecsModel) {
            if (value.RecordSpecsModel.IsNewRecord) {
                this._enabled(false);
                this._disableReason(LABELS.NEW_RECORD);
                return;
            }
            if (value.RecordSpecsModel.IsDisabled) {
                this._enabled(false);
                this._disableReason(NOTIFICATIONS.RECORD_DISABLED);
                return;
            }
        }

        var condition = true;
        let disableReason = null;
        var screen = this._form.GetScreen();
        if (screen) {
            if (screen.GetActionSubjectRecord()) {
                condition = !screen.GetActionSubjectRecord().IsFollowUpDisabled;
                disableReason = screen.GetActionSubjectRecord().DisabledReason;
            } else if (screen.IsConsultScreen) {
                condition = false;
            }
        }

        if (value.Data && value.Data.Rights) {
            condition = condition && value.Data.Rights.IsEditingAllowed && value.Data.Rights.IsRecordSecurityOk && this.IsEditScreenExist;
            this._enabled(condition);
            this._disableReason(disableReason);
        }
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
    }

    private ShowCheckLists(result: LifeStatusesGeneralModel) {
        const actionSubject = result.Child.ActionSubjectRecord;
        const checkLists = result.Child.CheckLists;

        if (!this._checkList) {
            this._checkList = new ActionCheckList({
                EntityId: actionSubject.EntityId,
                RecordId: actionSubject.RecordId,
                FollowUpMode: true,
                CurrentLifestatus: result.Child.CurrentLifeStatus
            });
            this._checkList.On('LIFESTATUS_CHOOSEN',
                this,
                eventArgs => {
                    this._followUpRecord.LifeStatusId = eventArgs.data.LifeStatus.Id || eventArgs.data.LifeStatus.LifeStatusId;
                    this._followUpRecord.LifeStatusSort = eventArgs.data.LifeStatus.Sort || eventArgs.data.LifeStatus.LifeStatusSort;
                    this._followUpRecord.LifeStatusName = eventArgs.data.LifeStatus.Name || eventArgs.data.LifeStatus.LifeStatusName;
                    this._followUpRecord.LifeStatusNoActionNode = eventArgs.data.LifeStatus.NoActionNode || eventArgs.data.LifeStatus.LifeStatusNoActionNode;
                    this._followUpRecord.ConfirmationPassword = eventArgs.data.Password;
                    this._followUpRecord.RetireChildren = eventArgs.data.RetireChildren;
                    this.GetForm().GetScreen().Trigger('FOLLOWUP');
                });
        }

        this._checkList.ProvideData(checkLists);
        this._checkList.Show();
    }

    private ShowStandardFollowUp(result: LifeStatusesGeneralModel) {
        this._followUpLifeStatuses.On('LIFESTATUS_CHOOSEN',
            this,
            eventArgs => {
                this._followUpRecord.LifeStatusId = eventArgs.data.LifeStatus.Id;
                this._followUpRecord.LifeStatusSort = eventArgs.data.LifeStatus.Sort;
                this._followUpRecord.LifeStatusName = eventArgs.data.LifeStatus.Name;
                this._followUpRecord.LifeStatusNoActionNode = eventArgs.data.LifeStatus.NoActionNode;
                this._followUpRecord.ConfirmationPassword = eventArgs.data.Password;
                this._followUpRecord.RetireChildren = eventArgs.data.RetireChildren;
                this.GetForm().GetScreen().Trigger('FOLLOWUP');
            });

        this._followUpLifeStatuses.On('PARENT_LIFESTATUS_CHOOSEN',
            this,
            eventArgs => {
                const parentRecord = new FollowUpParentRecordModel();
                parentRecord.ParentActionSubject = result.Parent.ActionSubjectRecord;
                parentRecord.CurrentLifeStatus = result.Parent.CurrentLifeStatus;
                parentRecord.LifeStatusId = eventArgs.data.LifeStatus.Id;
                parentRecord.LifeStatusSort = eventArgs.data.LifeStatus.Sort;
                parentRecord.LifeStatusName = eventArgs.data.LifeStatus.Name;
                parentRecord.LifeStatusNoActionNode = eventArgs.data.LifeStatus.NoActionNode;
                parentRecord.ConfirmationPassword = eventArgs.data.Password;
                this._followUpRecord.FollowUpParentRecord = parentRecord;
                this.GetForm().GetScreen().Trigger('FOLLOWUP');
            });

        this._followUpLifeStatuses.On('CHILD_LIFESTATUS_CHOOSEN',
            this,
            eventArgs => {
                this._followUpRecord.LifeStatusId = eventArgs.data.LifeStatus.Id;
                this._followUpRecord.LifeStatusSort = eventArgs.data.LifeStatus.Sort;
                this._followUpRecord.LifeStatusName = eventArgs.data.LifeStatus.Name;
                this._followUpRecord.ConfirmationPassword = eventArgs.data.Password;
                this._followUpRecord.LifeStatusNoActionNode = eventArgs.data.LifeStatus.NoActionNode;
            });

        this._followUpLifeStatuses.On('DONE',
            this,
            () => {
                this.GetForm().GetScreen().Trigger('FOLLOWUP_DONE');
            });

        this._followUpLifeStatuses.ShowInModal();
    }
}