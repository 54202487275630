import { record } from 'rrweb';
import * as ko from 'knockout';
import * as moment from 'moment';
import * as _ from "underscore";
import 'fullcalendar';
import 'jquery';

import {AGENDA_LIFE_STATUSES, AGENDA_TYPES} from "Core/Constants/AgendaTypes";
import {LifeStatuses} from "Core/Enums/LifeStatuses";
import {P} from 'Core/Common/Promise';
import {IControlParam} from 'Core/Screens/IScreen';
import {AgendaDataStore, ICreateAppointmentFromTodoRequestModel} from 'Core/Controls/Agenda/Stores/AgendaDataStore';
import {
    CONTROL_TYPES,
    DEFAULT_ICONS,
    FIELD_TYPES,
    RenderModes,
    SYSTEM_TABLE_NAMES,
    TABLE_TYPES,
    LIFE_STATUS_GROUPS
} from 'Core/Constant';
import {BlockUI} from 'Core/Common/BlockUi';
import {ScreenDataModel} from 'Core/ScreenManager/Models/ScreenDataModel';
import {RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel';
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel';
import {ComplexControl} from 'Core/Controls/ComplexControl/ComplexControl';
import {RequiredFieldModel} from 'Core/Controls/ComplexControl/Models/RequiredFieldModel';
import {TableTypesModel} from 'Core/Screens/TypeScreen/Models/TableTypesModel';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {
    AgendaItemModel,
    AgendaItemPerDay,
    DaysSchedule,
    LinkRecord,
    LinkTableModel
} from 'Core/Controls/Agenda/Models/AgendaItemModel';
import {UserEntityRelationsViewModel} from 'Core/Controls/LinkList/Models/LinkListRelationsViewModel';
import {PlanningItemModel, PlannerItemWeekButton, UserProgress} from 'Core/Controls/Agenda/Models/PlanningItemModel'
import {Notifier} from 'Core/Common/Notifier';
import {RecordStore} from 'Core/Screens/Stores/RecordStore';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {
    DeletionModeDialog,
    EVENTS as DELETION_MODE_DIALOG_EVENTS
} from 'Core/Components/Dialogs/DeletionModeDialog/DeletionModeDialog';
import {DeletionModeModel} from "Core/Screens/Models/DeletionModeModel";
import {Tooltip} from 'Core/Common/Tooltip';
import {UserManager} from 'User/UserManager';
import {Icon} from 'Core/Icon/Icon';
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {FormatConverter} from 'FormatEditor/FormatConverter';
import {ColorConverter} from "Core/Components/ColorSelector/ColorConverter";
import {MobileChecker} from 'Core/Common/MobileChecker';
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';
import {FullCalendarCultures} from 'Core/FullCalendar/FullCalendarCultures';

import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {LOCK_EVENTS, LockManager} from "Core/Components/Locker/LockManager";
import {Tab} from "../Tab/Tab";
import {TabPage} from "../TabPage/TabPage";
import {Scheduler} from "../Scheduler/Scheduler";
import {DataModes} from 'Core/Enums/DataModes';
import {FollowUpRecordModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel";
import {TABLE_SECURITY_WORDS} from "Core/Constants/TableSecurityWords";
import {SECURITY_LEVELS} from "Core/Constants/SecurityLevels";
import {IControlValue} from "../BaseControl/BaseControl";
import {UserVarsManager} from "../../UserVarsManager/UserVarsManager";
import {SelectUser} from '../SelectUser/SelectUser';
import {SelectUserFormModel} from 'Core/Controls/SelectUser/Models/SelectUserFormModel';
import {LinkList} from 'Core/Controls/LinkList/LinkList';
import {NewRelationModel} from "Core/Controls/LinkList/Models/NewRelationModel";
import {FollowupModes} from 'Core/Constants/FollowupModes';

import {GeneralProperties} from "../../GeneralProperties/GeneralProperties";
import AgendaConfig from 'Core/Controls/Agenda/Configs/agenda-config.json';
import {PROPERTIES} from "./Constants";
import { ILinkedResources, IRePlanningData } from 'Core/Controls/Scheduler/Interfaces';
import {ZIndexManager} from "Core/Common/ZIndexManager";

//Templates
import ViewTemplate from 'Core/Controls/Agenda/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/Agenda/Templates/ToolBar.html';
import EventTemplate from 'Core/Controls/Agenda/Templates/EventTemplate.html';
import EventMonthTemplate from 'Core/Controls/Agenda/Templates/EventMonthTemplate.html';
import PlanningTemplate from 'Core/Controls/Agenda/Templates/PlanningTemplate.html';
import LinksTooltipTemplate from 'Core/Controls/Agenda/Templates/LinksTooltip.html';
import EventListTemplate from 'Core/Controls/Agenda/Templates/EventListTemplate.html';
import EventTimeTemplate from 'Core/Controls/Agenda/Templates/EventTimeTemplate.html';
import {TableTypeModel} from "../../Screens/TypeScreen/Models/TableTypeModel";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { CookieManager } from '../../Common/CookieManager';
import { SignalRNotificationManager } from '../../Components/SignalR/SignalRNotificationManager';

ko.templates['Core/Controls/Agenda/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Agenda/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Agenda/Templates/List'] = ViewTemplate;
ko.templates['Core/Controls/Agenda/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Agenda/Templates/Design'] = ComplexControl.designTemplate;
ko.templates['Core/Controls/Agenda/Templates/EventTemplate'] = EventTemplate;
ko.templates['Core/Controls/Agenda/Templates/EventMonthTemplate'] = EventMonthTemplate;
ko.templates['Core/Controls/Agenda/Templates/PlanningTemplate'] = PlanningTemplate;
ko.templates['Core/Controls/Agenda/Templates/LinksTooltip'] = LinksTooltipTemplate;
ko.templates['Core/Controls/Agenda/Templates/EventListTemplate.html'] = EventListTemplate;
ko.templates['Core/Controls/Agenda/Templates/EventTimeTemplate.html'] = EventTimeTemplate;

const FULL_CALENDAR_VIEW = {
    MONTH: 'month',
    AGENDA_WEEK: 'agendaWeek',
    AGENDA_FIVE_DAY: 'agendaFiveDay',
    AGENDA_DAY: 'agendaDay',
    AGENDA_LIST_WEEK : 'listWeek',
    AGENDA_TIME_LINE_WEEK: 'timelineWeek'
};
const DEFAULT_APPOINTMENT_DURATION = 30;

(moment as any).createFromInputFallback = config => {
    config._d = new Date(config._i);
};

function isMobileWidth() {
    return $(window).width() <= 700;
}

export class Agenda extends ComplexControl {
    private _value: KnockoutObservable<string>;
    private _firstFreezeTimeDate: moment.Moment;
    private _loadInProgress: KnockoutObservable<boolean>;
    private _checkedRemoveToDo: KnockoutObservable<boolean>;
    private _calendarEvents: KnockoutObservableArray<any>;
    private _isVisibleTodoBlock: KnockoutObservable<boolean>;
    private _tablesTypes: TableTypesModel;
    private _allEvents: Array<AgendaItemModel>;
    private _plannings: Array<PlanningItemModel>;
    private _agendaItemsPerDay: Array<AgendaItemPerDay>;
    private _todoEvents: KnockoutObservableArray<AgendaItemModel>;
    private _recordTypeField: AttachedFieldModel;
    private _recordNameField: AttachedFieldModel;
    private _startDateField: AttachedFieldModel;
    private _eventColorField: AttachedFieldModel;
    private _timeBeforeField: AttachedFieldModel;
    private _timeAfterField: AttachedFieldModel;
    private _natureField: AttachedFieldModel;
    private _placeField: AttachedFieldModel;
    private _durationField: AttachedFieldModel;
    private _descriptionField: AttachedFieldModel;
    private _endingField: AttachedFieldModel;
    private _completenessField: AttachedFieldModel;
    private _enableTooltip: boolean;
    private _todoEventName: KnockoutObservable<string>;
    private _todoToAppointmentItem: AgendaItemModel;
    private _selectedUsers: number[];
    private _appointmentAttendees: KnockoutObservable<SelectUserFormModel>;
    private _showPlanned: boolean;
    private _owners: KnockoutObservableArray<any>;
    private _groupByResource: KnockoutObservable<boolean>;
    private _isInteractionWithUserSelect: KnockoutObservable<boolean>;
    private _timeoutId: number;
    private _disableDropEvtsBtwnDiffRes: boolean;
    private _selectedStartDate: string;
    private _selectedEndDate: string;
    private _viewRenderMode: string;
    private _appointmentEvents: Array<AgendaItemModel>;
    private _businessHours: any;
    private _specialDays: KnockoutObservableArray<any>;
    private _currentDay: any;
    private _isReturned: boolean;
    private _controlId: number;
    private _viewModels: KnockoutObservableArray<any>;
    private _isDataLoaded: boolean;
    protected _isRendered: KnockoutObservable<boolean>;
    private _agendaViewContainer: any;

    private _isToDoVisible: KnockoutObservable<boolean>;
    private _isSpecialDayVisible: KnockoutObservable<boolean>;
    private _isRequestLeaveVisible: KnockoutObservable<boolean>;
    private _isReportIllnessVisible: KnockoutObservable<boolean>;
    private _isRecurrentVisible: KnockoutObservable<boolean>;
    private _isSmallAppointments: boolean;
    private _signalRHub: HubConnection;

    constructor(params: IControlParam) {
        super(params, AgendaConfig);

        this._calendarEvents = ko.observableArray([]);
        this._isVisibleTodoBlock = ko.observable(false);
        this._allEvents = [];
        this._todoEvents = ko.observableArray([]);
        this._todoEventName = ko.observable('');
        this._selectedUsers = [];
        this._appointmentAttendees = ko.observable(null);
        this._loadInProgress = ko.observable(false);
        this._checkedRemoveToDo = ko.observable(false);
        this._enableTooltip = true;
        this._owners = ko.observableArray([]);
        this._groupByResource = ko.observable(false);
        this._isInteractionWithUserSelect = ko.observable(null);
        this._timeoutId = null;
        this._disableDropEvtsBtwnDiffRes = false; //Disable drop events between different Resources/Groups/Owners
        this._viewRenderMode = FULL_CALENDAR_VIEW.AGENDA_FIVE_DAY;
        this._appointmentEvents = [];
        this._viewModels = ko.observableArray([]);
        this._businessHours = ko.observable(false);
        this._specialDays = ko.observableArray([]);
        this._currentDay = new Date();
        this._isDataLoaded = false;
        this._isReturned = false;
        this._agendaViewContainer = null;
        this._controlId = params.Model.Id;
        this._isSmallAppointments = false;

        this.Init();
    }

    private Init(): void {
        if (this.GetRenderMode() !== RenderModes.Design && this.GetRenderMode() !== RenderModes.ToolBar) {
            [
                this._recordNameField,
                this._recordTypeField,
                this._natureField,
                this._placeField,
                this._startDateField,
                this._durationField,
                this._eventColorField,
                this._timeBeforeField,
                this._timeAfterField,
                this._descriptionField,
                this._endingField,
                this._completenessField
            ] = this._model().Fields;
        }

        this._requiredFields([
            new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('F_TYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('F_NATURE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('F_PLACE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('STARTING', FIELD_TYPES.DateTime, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('DURATION', FIELD_TYPES.TimeSpan, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('COLOR', FIELD_TYPES.Color, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('TIMEBEFORE', FIELD_TYPES.TimeSpan, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('TIMEAFTER', FIELD_TYPES.TimeSpan, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('MEMO', FIELD_TYPES.Memo, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('ENDING', FIELD_TYPES.DateTime, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('COMPLETENESS', FIELD_TYPES.Decimal, TABLE_TYPES.Entity, null)
        ]);

        this.ApplyProperties();
        this.InitRequiredFields();

        if (this._renderMode() === RenderModes.View || this._renderMode() === RenderModes.Edit) {
            const screen = this._form && this._form.GetScreen();

            screen.On('UsersSelectionChanged', this, eventArgs => {
                const uniqueGroupIds = _.uniq(_.pluck(eventArgs.data.SelectedUsersAndGroups, 'GroupId'));
                const uniqueUserIds = _.uniq(_.flatten(_.pluck(eventArgs.data.SelectedUsersAndGroups, 'UserIds')));

                this._selectedUsers = _.union(uniqueGroupIds, uniqueUserIds);
                if (this._selectedUsers && this._selectedUsers.length > 0) {
                    this._selectedUsers = this._selectedUsers.filter(userId => userId !== null);
                }
                this._showPlanned = eventArgs.data.ShowPlanned;

                let appointmentAttendees = eventArgs.data.AppointmentAttendees;
                if (appointmentAttendees?.UserGroups.length === 0 && appointmentAttendees?.Users.length === 0) {
                    appointmentAttendees = null;
                }
                this._appointmentAttendees(appointmentAttendees ? new SelectUserFormModel(appointmentAttendees) : null);

                if (this._selectedUsers.length === 0) {
                    this._selectedUsers.push(UserManager.Instance.CurrentUser.Id);
                }

                if (this._selectedStartDate && this._selectedEndDate && this._isRendered()) {
                    this.Clean(this._agendaViewContainer);
                    this._isInteractionWithUserSelect(true);

                    if (!this._appointmentAttendees()) {
                        this._isInteractionWithUserSelect(false);
                    }

                    this.LoadData({ groupByResource: this._isInteractionWithUserSelect()});
                }
            });

            this.AddEvent('AGENDA_CHANGED');
            this.On('AGENDA_CHANGED', this, () => screen.Trigger('AGENDA_CHANGED'));

            if (this._selectedUsers.length === 0) {
                this._selectedUsers.push(UserManager.Instance.CurrentUser.Id);
            }
        }

        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Agenda));
        SignalRNotificationManager.Instance.On('UPDATE_AGENDA', this, (evtArgs)=>{
            this.LoadData({ recordId: evtArgs.data.id, operation: evtArgs.data.operation });
        });
    }

    get IsWeekView() {
        return this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_WEEK;
    }

    get IsWorkWeekView() {
        return this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_FIVE_DAY;
    }

    ApplyProperties() {
        this._isToDoVisible = this.GeneralProperties.GetPropertyValue(PROPERTIES.TODO);
        this._isSpecialDayVisible = this.GeneralProperties.GetPropertyValue(PROPERTIES.SPECIAL_DAY);
        this._isRequestLeaveVisible = this.GeneralProperties.GetPropertyValue(PROPERTIES.REQUEST_LEAVE);
        this._isReportIllnessVisible = this.GeneralProperties.GetPropertyValue(PROPERTIES.REPORT_ILLNESS);
        this._isRecurrentVisible = this.GeneralProperties.GetPropertyValue(PROPERTIES.ADD_RECURRENT);
        this._isSmallAppointments = this.GeneralProperties.GetPropertyValue(PROPERTIES.SMALL_APPOINTMENTS);
    }

    SetLastDate() {
        const lastDate = UserVarsManager.Instance.GetAgendaDate(this._controlId);
        if (lastDate) {
            this._selectedStartDate = moment(lastDate).format();
        } else {
            this.SetDate(this._controlId, this._selectedStartDate);
        }
    }

    SetDate(controlId: number, date: string) {
        UserVarsManager.Instance.SetAgendaDate(controlId, date);
        const screen = this._form && this._form.GetScreen();
        const schedulers = screen.GetControlsByType(CONTROL_TYPES.Scheduler);

        _.each(schedulers, scheduler => {
            UserVarsManager.Instance.SetSchedulerDate(scheduler.GetControlId(), date);
        });
    }


    private Clean(element?: JQuery) {
        this._todoEvents([]);
        this._allEvents = [];
        this._calendarEvents([]);
        this._owners([]);
        this._businessHours = ko.observable(false);

        if (element){
            element.find('.fc-nonbusiness').remove();
        }
    }

    private SetSpecialDays(userSpecialDays: Array<any>) {
        if (userSpecialDays.length > 0 && this._viewRenderMode !== FULL_CALENDAR_VIEW.AGENDA_DAY) {
            let dataspecialDays = userSpecialDays[0].SpecialDays;
            let specialDays = [];
            _.each(dataspecialDays, (day: any) => {
                if (day.FreeDay) {
                    let date = FormatConverter.FormatToDay(day.Date);
                    let start = date.format(DATE_FORMATS.FULL_DATETIME.Format);
                    let end = date.add(1, 'days').format(DATE_FORMATS.FULL_DATETIME.Format);
                    specialDays.push({
                        title: day.Name,
                        start: start,
                        end: end,
                        rendering: 'background',
                        backgroundColor: 'red',
                        country: day.Country && day.Country.Name,
                        allDay: false,
                        specialDay: true
                    })
                }

                if (this._viewRenderMode === FULL_CALENDAR_VIEW.MONTH) {
                    _.each(specialDays, (value: any) => {
                        value.allDay = true;
                    });
                }
            });
            this._specialDays(specialDays);
        } else {
            this._specialDays([]);
        }
    }

    private SetUsersSchedule(usersSchedule: Array<any>) {
        if (usersSchedule.length === 1 && this._viewRenderMode !== FULL_CALENDAR_VIEW.AGENDA_DAY) {
            let daysSchedule = usersSchedule[0].DaysSchedule;
            let businessHours = [];
            _.each(daysSchedule, (day: DaysSchedule) => {
                const start = moment(FormatConverter.CorrectTimezone(day.Starting));
                const end = moment(FormatConverter.CorrectTimezone(day.Ending));
                const weekday = start.day();
                businessHours.push({
                    dow: [weekday],
                    start: start.format(DATE_FORMATS.TIME.Format),
                    end: end.format(DATE_FORMATS.TIME.Format)
                });
            });
            this._businessHours = ko.observableArray(businessHours);

        } else {
            this._businessHours = ko.observable(false);
        }
    }

    private SetAgendaDayData(agendaItemsPerDay, usersSchedule, userSpecialDays) {
        this._agendaItemsPerDay = agendaItemsPerDay;
        this._allEvents = [];
        this._agendaItemsPerDay.map((agendaPerDay) => {
            agendaPerDay.Item.SharedWith = {
                UserId: agendaPerDay.UserId,
                Name: agendaPerDay.Name
            };
            this._allEvents.push(agendaPerDay.Item);
        });

        _.each(this._agendaItemsPerDay, (item: any) => {
            let currentSpecialDay = _.find(userSpecialDays, (user: any) => {
                return item.UserId === user.UserId;
            });

            item.FreeDay = false;

            if (currentSpecialDay && currentSpecialDay.SpecialDays.length) {
                let currentDay = _.find(currentSpecialDay.SpecialDays, (day: any) => {
                    let date = FormatConverter.FormatToDay(day.Date, DATE_FORMATS.SHORT_DATE.Format);
                    return this._currentDay === date;
                });

                if (currentDay) {
                    item.FreeDay = currentDay.FreeDay;
                    let date = FormatConverter.FormatToDay(currentDay.Date);

                    item.SpecialDayStart = date.format(DATE_FORMATS.FULL_DATETIME.Format);
                    item.SpecialDayEnd = date.add(1, 'days').format(DATE_FORMATS.FULL_DATETIME.Format);
                }
            }

            let current = _.find(usersSchedule, (user: any) => {
                return item.UserId === user.UserId;
            });

            if (current && current.DaysSchedule.length) {
                const daySchedule = _.find(current.DaysSchedule, (schedule: any) => {
                    const date = moment(schedule.Starting).format(DATE_FORMATS.SHORT_DATE.Format);
                    return this._currentDay === date;
                });

                if (daySchedule) {
                    const start = moment(FormatConverter.CorrectTimezone(daySchedule.Starting));
                    const end = moment(FormatConverter.CorrectTimezone(daySchedule.Ending));

                    item.Start = start.format(DATE_FORMATS.TIME.Format);
                    item.End = end.format(DATE_FORMATS.TIME.Format);
                }
            }

            item.Rendering = 'background';
            item.BackgroundColor = 'red';
        });

    }

    private SetAgendaWeakOrWorkWeekData(agendaItemsPerDay,  usersSchedule, userSpecialDays) {
        this._agendaItemsPerDay = agendaItemsPerDay;
        this._allEvents = [];
        this._agendaItemsPerDay.map((agendaPerDay: AgendaItemPerDay) => {
            agendaPerDay.Item.SharedWith = {
                UserId: agendaPerDay.UserId,
                Name: agendaPerDay.Name
            };
            this._allEvents.push(agendaPerDay.Item);
        });

        _.each(this._agendaItemsPerDay, (item: AgendaItemPerDay) => {
            let currentSpecialDay = _.find(userSpecialDays, (user: any) => {
                return item.UserId === user.UserId;
            });

            item.FreeDay = false;

            if (currentSpecialDay && currentSpecialDay.SpecialDays.length) {
                let currentDay = _.find(currentSpecialDay.SpecialDays, (day: any) => {
                    let date = FormatConverter.FormatToDay(day.Date, DATE_FORMATS.SHORT_DATE.Format);
                    return this._currentDay === date;
                });

                if (currentDay) {
                    item.FreeDay = currentDay.FreeDay;
                    let date = FormatConverter.FormatToDay(currentDay.Date);

                    item.SpecialDayStart = date.format(DATE_FORMATS.FULL_DATETIME.Format);
                    item.SpecialDayEnd = date.add(1, 'days').format(DATE_FORMATS.FULL_DATETIME.Format);
                }
            }

            let current = _.find(usersSchedule, (user: any) => {
                return item.UserId === user.UserId;
            });

            if (current && current.DaysSchedule.length) {
                const daySchedule = _.find(current.DaysSchedule, (schedule: any) => {
                    const date = moment(schedule.Starting).format(DATE_FORMATS.SHORT_DATE.Format);

                    return this._currentDay === date;
                });

                if (daySchedule) {
                    const start = moment(FormatConverter.CorrectTimezone(daySchedule.Starting));
                    const end = moment(FormatConverter.CorrectTimezone(daySchedule.Ending));

                    item.Start = start.format(DATE_FORMATS.TIME.Format);
                    item.End = end.format(DATE_FORMATS.TIME.Format);
                }
            }

            item.Rendering = 'background';
            item.BackgroundColor = 'red';
        });

    }

    GetShortDateFormat() {
        return DATE_FORMATS.SHORT_DATE['Format'];
    }

    GetMonthAndYearFormat() {
        return DATE_FORMATS.MONTH_AND_YEAR['Format'];
    }

    LoadData({ groupByResource = false, recordId, operation } : { groupByResource?: boolean, recordId?: number, operation?: string } = {}): P.Promise<any> {
        if(recordId != null){
            this.RemoveDuplicatesNonBusiness();
        }        

        if(!recordId){
            this._loadInProgress(true);
        }

        const params = {
            ControlId: this.GetControlId(),
            UsersId: this._selectedUsers,
            ShowPlanned: this._showPlanned,
            SubjectId: this.GetForm().GetScreen().GetEntityId(),
            SubjectRecordId: this.GetForm().GetScreen().GetRecordId(),
            StartDate: this._selectedStartDate,
            EndDate: this._selectedEndDate,
            RecordId: recordId,

            GetScheduleRequestModel: {
                StartDate: FormatConverter.ShiftTimeZone(this._selectedStartDate),
                EndDate: FormatConverter.ShiftTimeZone(this._selectedEndDate),
                UsersId: this._selectedUsers
            },
            GetSpecialDaysRequestModel: {
                StartDate: this._selectedStartDate,
                EndDate: this._selectedEndDate,
                UsersId: this._selectedUsers
            },
            GetTableTypesModel: {
                EntityId: this.FieldModel.EntityId,
                ParentTypeId: 0,
                WithRoot: false,
                OnlyEnabled: true
            }
        };

        var deferredResult = P.defer<any>();

        AgendaDataStore.GetData(params)
            .always(() => {
                this._loadInProgress(false);
            })
            .then((data: any) => {

                if(!recordId){
                    this._allEvents = data.AgendaItems;
                }else{
                    const existsEvent = _.find(this._allEvents, (event: any) => event.RecordId === recordId);
                    let newEvent = data.AgendaItems[0];
                    if(existsEvent){
                        this._allEvents.splice(this._allEvents.indexOf(existsEvent), 1);
                    }
                    
                    if(newEvent){
                        this._allEvents.push(newEvent);
                    }else{
                        return;
                    }
                }
                
                if(!recordId){
                    
                    this._tablesTypes = data.TableTypes;
                    const {
                        AGENDA_DAY,
                        AGENDA_FIVE_DAY,
                        AGENDA_WEEK,
                        AGENDA_LIST_WEEK,
                        AGENDA_TIME_LINE_WEEK
                    } = FULL_CALENDAR_VIEW;
                    const isAgendaDayOrListOrTime = [AGENDA_DAY, AGENDA_LIST_WEEK, AGENDA_TIME_LINE_WEEK].includes(this._viewRenderMode);
                    const isAgendaWeakOrWorkWeek = [AGENDA_WEEK, AGENDA_FIVE_DAY].includes(this._viewRenderMode);

                    this._firstFreezeTimeDate = moment(data.FirstFreezeTimeDate);

                    let userSpecialDays = data.UserSpecialDays.UserSpecialDays;
                    let usersSchedule = data.UserSchedule.UsersSchedule;

                    this.SetSpecialDays(userSpecialDays);
                    this.SetUsersSchedule(usersSchedule);


                    if (isAgendaDayOrListOrTime) {
                        const { AgendaItemsPerDay } = data;
                        this.SetAgendaDayData(AgendaItemsPerDay, usersSchedule, userSpecialDays);
                        if (this._isDataLoaded || !isMobileWidth()) {
                            this.ShowToDoBlock();
                        }
                    } else if (isAgendaWeakOrWorkWeek){
                        const { AgendaItemsPerDay } = data;
                        this.SetAgendaWeakOrWorkWeekData(AgendaItemsPerDay, usersSchedule, userSpecialDays);
                    } 

                    if(this._viewRenderMode !== FULL_CALENDAR_VIEW.AGENDA_DAY){
                        this._plannings = data.PlanningItems;
                    }

                    this.MapOwnersList();                
                    this._groupByResource(groupByResource);
                    this.CustomScrollToHour(8);
                }
                
                this.PrepareData({ recordId: recordId, operation: operation });

                this._isDataLoaded = true;
                deferredResult.resolve(null);
            })
            .fail(error => {
                new Notifier().Failed(error.message);
                this.Clean();
                deferredResult.reject({message: error.message});
            });

        return deferredResult.promise();
    }

    MapOwnersList() {
        let ids = [];
        let owners = {};

        const {
            AGENDA_WEEK,
            AGENDA_FIVE_DAY,
            AGENDA_DAY,
            AGENDA_LIST_WEEK,
            AGENDA_TIME_LINE_WEEK
        } = FULL_CALENDAR_VIEW;
        const isAgendaDayOrListOrTime = [AGENDA_DAY, AGENDA_LIST_WEEK, AGENDA_TIME_LINE_WEEK].includes(this._viewRenderMode);
        const isAgendaWeakOrWorkWeek = [AGENDA_WEEK, AGENDA_FIVE_DAY].includes(this._viewRenderMode);

        if (isAgendaDayOrListOrTime) {
            this._agendaItemsPerDay.forEach((el) => {
                ids.push(el.UserId);

                owners[el.UserId] = {
                    id: el.UserId,
                    title: el.Name,
                    businessHours: {
                        start: el.Start || '00:00',
                        end: el.End || '00:00'
                    },
                    freeDay: el.FreeDay,
                    start: el.SpecialDayStart,
                    end: el.SpecialDayEnd
                };
            });

        } else if (isAgendaWeakOrWorkWeek) {

            this._agendaItemsPerDay.forEach((el) => {
                ids.push(el.UserId);

                owners[el.UserId] = {
                    id: el.UserId,
                    title: el.Name,
                    businessHours: {
                        start: el.Start || '00:00',
                        end: el.End || '00:00'
                    }
                    // freeDay: el.FreeDay,
                    // start: el.SpecialDayStart,
                    // end: el.SpecialDayEnd
                };
            });

        } else {
            this._allEvents.forEach((el) => {
                ids.push(el.Owner.Id);
                owners[el.Owner.Id] = {'id': el.Owner.Id, 'title': el.Owner.Name};
            });
        }

        ids = _.uniq(ids);
        this._owners(ids.map((id) => owners[id]));


        if (this._timeoutId) {
            // console.log('clear Timeout - '+ this._timeoutId);
            clearTimeout(this._timeoutId);
        }
        if (isAgendaWeakOrWorkWeek) {
            const days = this._viewRenderMode === AGENDA_FIVE_DAY ? 5 :
                this._viewRenderMode === AGENDA_WEEK ? 7 : null;
            if (days) {
                let minColumnWidth = this._isSmallAppointments ? 20 : 100;
                this._timeoutId = setTimeout(() => {
                    this.SetCellMinWidthOfDays(minColumnWidth, days);
                }, 200);
                // console.log('timeoutId - '+this._timeoutId)
            }
        } else if (this._viewRenderMode === AGENDA_DAY) {
            this._timeoutId = setTimeout(() => {
                this.SetCellMinWidthOfDays(200);
            }, 200);
        }
    }

    private RemoveDuplicatesNonBusiness() {
        if (this._groupByResource() && this._agendaViewContainer){
            const element = this._agendaViewContainer.find('.fc-time-grid-container .fc-content-skeleton');
            $(element).remove();
        }
    }

    private SetCellMinWidthOfDays(minColumnWidth: number, days: number = 1): void {
        const minColumnW: number = minColumnWidth;
        const numberOfDays = days;
        const $el = $(this._el);
        const elW = $el.width();
        const ownersQty = this._owners().length * numberOfDays;
        const minWidth = minColumnW * ownersQty;
        let agendaWeekWorkWekDay = $el.find('.fc-agendaFiveDay-view, .fc-agendaWeek-view, .fc-agendaDay-view');

        agendaWeekWorkWekDay.css('min-width', minWidth > elW ? minWidth + 'px' : 'unset');
    }
    private FindType(name: string){
        return _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === name);
    }

    private PrepareData({ recordId, operation } : { recordId: number, operation: string }) {
        const todoEvents: Array<AgendaItemModel> = [];
        const appointmentEvents: Array<AgendaItemModel> = [];
        const appointmentType =
            _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Appointment);

        const todoType =
            _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Todo);

        const taskType =
            _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Task);

        const holidayType =
            _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Holiday);

        const illnessType =
            _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Illness);

        const calendarEvents = [];

        if (this._allEvents.length > 0) {
            _.each(this._allEvents, event => {
                _.each(event.Values, value => {
                    if (value.FieldTypeName === FIELD_TYPES.DateTime || value.FieldTypeName === FIELD_TYPES.Time) {
                        value.Value = FormatConverter.CorrectTimezone(value.Value, false);
                    }

                    if (value.FieldTypeName === FIELD_TYPES.Color) {
                        value.Value = ColorConverter.ToHex(value.Value);
                    }

                    if (value.FieldId === this._recordTypeField.Id) {
                        if (parseInt(value.Value) === todoType.Id) {
                            todoEvents.push(event);
                        }

                        if (parseInt(value.Value) === appointmentType.Id || parseInt(value.Value) === taskType.Id) {
                            appointmentEvents.push(event);
                        }

                        if (parseInt(value.Value) === holidayType.Id || parseInt(value.Value) === illnessType.Id) {
                            const fullCalendarEventModel = this.GetFullCalendarHolidayAndIllnessEventModel(event);
                            calendarEvents.push(fullCalendarEventModel);
                        }
                    }
                });
            });
        }

        this._todoEvents(todoEvents);
        this._appointmentEvents = appointmentEvents;

        _.each(appointmentEvents, event => {
            const fullCalendarEventModel = this.GetFullCalendarEventModel(event);

            if (fullCalendarEventModel) {
                calendarEvents.push(fullCalendarEventModel);
            }
        });

        const {AGENDA_WEEK, AGENDA_FIVE_DAY} = FULL_CALENDAR_VIEW;
        const isAgendaWeekAndWorkWeek = [AGENDA_FIVE_DAY, AGENDA_WEEK].includes(this._viewRenderMode);
        if (isAgendaWeekAndWorkWeek) {
            _.each(this._plannings, event => {
                const fullCalendarEventModel = this.GetFullCalendarPlanningEventModel(event);
                calendarEvents.push(fullCalendarEventModel);
            });
        }

        this._calendarEvents(calendarEvents);
    }

    private GetHoliday(eventModel: AgendaItemModel): boolean {
        let typeIdValue = +this.GetValueByFieldId(eventModel, this._recordTypeField.Id);
        let holidayType =
            _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Holiday);

        return holidayType.Id === typeIdValue;
    }

    private GetLifeStatusName(eventModel: AgendaItemModel): string {
        return eventModel && !!eventModel.LifeStatusInfo && !!eventModel.LifeStatusInfo.LifeStatusName ? eventModel.LifeStatusInfo.LifeStatusName : null;
    }

    private GetCalendarEventColor(eventModel: AgendaItemModel, eventColor: string, defaultColor: string): string {
        const disabledColor = this.IsLifeStatusDisabled(eventModel) ? '#c6c6c6' : null;
        return disabledColor || eventColor || defaultColor;
    }

    private IsHolidayIcon(eventModel: AgendaItemModel): boolean {
        return this.GetLifeStatusName(eventModel) === AGENDA_LIFE_STATUSES.Requested;
    }

    private IsLifeStatusDisabled(eventModel: AgendaItemModel): boolean {
        return this.GetLifeStatusName(eventModel) === LifeStatuses.Disabled.Name;
    }

    private GetHolidayTranslatedTooltip(eventModel: AgendaItemModel): string {
        let tooltipText = null;
        if (eventModel && !!eventModel.LifeStatusInfo) {
            tooltipText = !!eventModel.LifeStatusInfo.LifeStatusTranslatedName ? eventModel.LifeStatusInfo.LifeStatusTranslatedName : eventModel.LifeStatusInfo.LifeStatusName;
        }
        return tooltipText;
    }

    private GetFullCalendarEventModel(eventModel: AgendaItemModel) {
        let fullCalendarEventModel = null;
        const nameValue = this.GetValueByFieldId(eventModel, this._recordNameField.Id);
        const nameTranslatedValue = this.GetTranslatedValueByFieldId(eventModel, this._recordNameField.Id);
        const typeIdValue = +this.GetValueByFieldId(eventModel, this._recordTypeField.Id);

        const illnessType =
            _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Illness);

        const typeNameValue = _.find(this._tablesTypes.TableTypes, tableType => tableType.Id === typeIdValue).Name;
        const owner = eventModel.Owner.Name;
        const ownerId = eventModel.Owner.Id;
        const sharedWith = eventModel.SharedWith;
        const startDateValue = this.GetValueByFieldId(eventModel, this._startDateField.Id);
        const eventColorValue = this.GetValueByFieldId(eventModel, this._eventColorField.Id);
        const placeColorValue = this.GetValueByFieldId(eventModel, this._placeField.Id);
        const timeBeforeValue = this.GetValueByFieldId(eventModel, this._timeBeforeField.Id);
        const timeAfterValue = this.GetValueByFieldId(eventModel, this._timeAfterField.Id);
        const durationValue = this.GetValueByFieldId(eventModel, this._durationField.Id);
        const descriptionValue = this.GetValueByFieldId(eventModel, this._descriptionField.Id);
        const translatedDescriptionValue = this.GetTranslatedValueByFieldId(eventModel, this._descriptionField.Id);
        const endingValue = this.GetValueByFieldId(eventModel, this._endingField.Id);

        const calendarEventColor = this.GetCalendarEventColor(eventModel, eventColorValue, placeColorValue);

        const startDate = moment(startDateValue);
        const timeBefore = moment(timeBeforeValue);
        const timeAfter = moment(timeAfterValue);
        const duration = moment(durationValue);
        const hoursDuration = moment.duration(duration.hours(), 'hours');
        const minutesDuration = moment.duration(duration.minutes(), 'minutes');

        let endDate = moment(startDate).add(hoursDuration);
        endDate = endingValue ? moment(endingValue) : moment(endDate).add(minutesDuration);

        const isAllDay = typeNameValue === AGENDA_TYPES.Task;

        let start = timeBeforeValue ? this.ShiftBackDate(startDate, timeBefore) : startDate;
        let end = timeAfterValue ? this.ShiftForwardDate(endDate, timeAfter) : endDate;

        if (isAllDay) {
            //end date is exclusive according to library
            end.add(1, 'days').add(-1, 'seconds');
        }

        if (nameValue && startDateValue) {
            fullCalendarEventModel = {
                id: this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? null : eventModel.RecordId,
                eventRecordId: eventModel.RecordId,
                title: nameTranslatedValue || nameValue,
                owner: owner,
                start: start.format(),
                end: end.format(),
                allDay: isAllDay,
                color: calendarEventColor,
                timeBefore: this.FormatTime(timeBeforeValue),
                timeBeforeHeigth: this.TimeShiftHeight(timeBefore),
                timeAfterHeigth: this.TimeShiftHeight(timeAfter),
                timeAfter: this.FormatTime(timeAfterValue),
                description: translatedDescriptionValue || descriptionValue,
                accepted: eventModel.Accepted,
                possibleToAccept: ko.observable(eventModel.PossibleToAccept),
                recurrentRecordId: eventModel.RecurrentRecordId,
                links: eventModel.Links,
                resourceId: sharedWith && sharedWith.UserId || ownerId,
                editable: eventModel.Editable && typeNameValue === AGENDA_TYPES.Appointment && !this.IsLifeStatusDisabled(eventModel),
                editableWithDisabledLifeStatus: eventModel.Editable && typeNameValue === AGENDA_TYPES.Appointment && this.IsLifeStatusDisabled(eventModel),
                isIllness: illnessType.Id === typeIdValue,
                isHoliday: this.GetHoliday(eventModel),
                isHolidayIcon: this.GetHoliday(eventModel) && this.IsHolidayIcon(eventModel),
                lifeStatusName: this.GetLifeStatusName(eventModel),
                holidayTooltip: this.GetHoliday(eventModel) ? this.GetHolidayTranslatedTooltip(eventModel) : null,
                originalResourceId: ko.observable(eventModel.OriginalResourceId),
                agenda: this
            };
        }

        return fullCalendarEventModel;
    }

    private GetFullCalendarHolidayAndIllnessEventModel(eventModel) {
        const nameValue = this.GetValueByFieldId(eventModel, this._recordNameField.Id);
        const nameTranslatedValue = this.GetTranslatedValueByFieldId(eventModel, this._recordNameField.Id);
        const owner = eventModel.Owner.Name;
        const ownerId = eventModel.Owner.Id;

        const typeIdValue = +this.GetValueByFieldId(eventModel, this._recordTypeField.Id);

        const illnessType =
            _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Illness);

        const isIllness = illnessType.Id === typeIdValue;

        const typeNameValue = _.find(this._tablesTypes.TableTypes, tableType => tableType.Id === typeIdValue).Name;

        const eventColorValue = this.GetValueByFieldId(eventModel, this._eventColorField.Id);
        const defaultColor = typeNameValue == 'Holiday' ? '#6ddba8' : '#c9db6d';
        const calendarEventColor = this.GetCalendarEventColor(eventModel, eventColorValue, defaultColor);

        const startDateValue = this.GetValueByFieldId(eventModel, this._startDateField.Id);
        const timeBeforeValue = this.GetValueByFieldId(eventModel, this._timeBeforeField.Id);
        const timeAfterValue = this.GetValueByFieldId(eventModel, this._timeAfterField.Id);
        const durationValue = this.GetValueByFieldId(eventModel, this._durationField.Id);
        const endingValue = this.GetValueByFieldId(eventModel, this._endingField.Id);

        const startDate = moment(FormatConverter.CorrectTimezone(startDateValue));
        const timeBefore = moment(timeBeforeValue);
        const timeAfter = moment(timeAfterValue);
        const duration = moment(durationValue);
        const hoursDuration = moment.duration(duration.hours(), 'hours');
        const minutesDuration = moment.duration(duration.minutes(), 'minutes');

        let endDate = moment(startDate).add(hoursDuration);
        endDate = endingValue ? moment(FormatConverter.CorrectTimezone(endingValue)).add(hoursDuration) : moment(endDate).add(minutesDuration);

        let currentDay = moment(FormatConverter.CorrectTimezone(new Date().toDateString()));
        if (isIllness && !endingValue && moment(startDate).isBefore(currentDay)) { //if the start is less than the current day
            endDate = currentDay;
        }

        let start = timeBeforeValue ? this.ShiftBackDate(startDate, timeBefore) : startDate;
        let end = timeAfterValue ? this.ShiftForwardDate(endDate, timeAfter) : endDate;
        end.add(1, 'days');

        const fullCalendarEventModel = {
            id: this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? null : eventModel.RecordId,
            eventRecordId: eventModel.RecordId,
            title: nameTranslatedValue || nameValue,
            owner: owner,
            allDay: true,
            start: start.format(),
            end: end.format(),
            color: calendarEventColor,
            editable: eventModel.Editable && !this.IsLifeStatusDisabled(eventModel),
            editableWithDisabledLifeStatus: eventModel.Editable && this.IsLifeStatusDisabled(eventModel),
            resourceId: ownerId,
            isIllness: isIllness,
            isHoliday: this.GetHoliday(eventModel),
            isHolidayIcon: this.GetHoliday(eventModel) && this.IsHolidayIcon(eventModel),
            lifeStatusName: this.GetLifeStatusName(eventModel),
            holidayTooltip: this.GetHoliday(eventModel) ? this.GetHolidayTranslatedTooltip(eventModel) : null,
            originalResourceId: ko.observable(eventModel.OriginalResourceId),
            agenda: this
        };

        return fullCalendarEventModel;
    }

    private GetFullCalendarPlanningEventModel(eventModel: PlanningItemModel) {

        const end = moment.utc(this._selectedEndDate).add(1, 'days').add(-1, 'seconds');
        const planningsAppointments = eventModel.PlanningAppointments.map(date => moment(FormatConverter.CorrectTimezone(date)));

        const appointmentsButtons = this.BuildAppointmentsWeekButtons(planningsAppointments);
        const bookingsButtons = this.BuildBookTimeWeekButtons();

        return {
            id: this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? null : eventModel.Id,
            eventRecordId: eventModel.Id,
            title: eventModel.CompetenceName,
            allDay: true,
            start: this._selectedStartDate,
            end: end,
            links: eventModel.Links,
            users: eventModel.Users,
            planning: true,
            appointmentsWeekButtons: appointmentsButtons,
            bookingsWeekButtons: bookingsButtons,
            getUserTitle: (user: UserProgress) => {
                return `${user.Name}: ${user.BookedHours} of ${user.DecHours}`
            }
        };
    }

    private BuildAppointmentsWeekButtons(plannigns: moment.Moment[]) {
        const appointmentsWeekButtons: Array<PlannerItemWeekButton> = this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_FIVE_DAY
            ? this.BuildWeekButtons().slice(0, 5)
            : this.BuildWeekButtons();

        appointmentsWeekButtons.forEach((button: any) => button.Disabled = _.any(plannigns, planning => planning.isSame(moment(button.Date), 'day')));
        return appointmentsWeekButtons;
    }

    private BuildBookTimeWeekButtons() {
        //Only working days should be in the book time week buttons list
        const bookTimeWeekButtons = this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_FIVE_DAY
            ? this.BuildWeekButtons().slice(0, 5)
            : this.BuildWeekButtons();
        bookTimeWeekButtons.forEach((button: any) => {
            //if difference in minutes is negative - then button's date is in future
            const isFuture = moment().diff(button.Date, 'minutes') < 0;
            //if difference in minutes is negative - then we are out of freeze time
            const isOutOfFreezeTime = moment(button.Date).diff(this._firstFreezeTimeDate, 'minutes') < 0;

            if (isFuture) {
                button.Disabled = true;
                button.DisabledReason = this._labels.WRITING_TIME_FOR_FUTURE_NOT_ALLOWED;
                return;
            }

            if (isOutOfFreezeTime) {
                button.Disabled = true;
                button.DisabledReason = this._labels.STARTING_DATE_IS_OUT_OF_FREEZE_TIME;
                return;
            }
        });

        return bookTimeWeekButtons;
    }

    private BuildWeekButtons() {
        const weekButtons: Array<PlannerItemWeekButton> = [];

        for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
            const date = moment(this._selectedStartDate).hours(9).add(dayOfWeek, 'days').format();
            const name = moment(this._selectedStartDate).add(dayOfWeek, 'days').format('dd');

            weekButtons.push({Name: name, Date: date, Disabled: false});
        }

        return weekButtons;
    }

    TimeShiftHeight(shiftTime: moment.Moment) {
        const hours = shiftTime.hours();
        const minutes = shiftTime.minutes();
        const totalMinutes = hours * 60 + minutes;
        const height = 66 / 60 * totalMinutes;

        return height;
    }

    ShiftBackDate(date: moment.Moment, shiftDate: moment.Moment) {
        const hoursDuration = moment.duration(shiftDate.hours() * -1, 'hours');
        const minutesDuration = moment.duration(shiftDate.minutes() * -1, 'minutes');

        date = moment(date).add(hoursDuration);
        date = moment(date).add(minutesDuration);

        return date;
    }

    ShiftForwardDate(date: moment.Moment, shiftDate: moment.Moment) {
        const hoursDuration = moment.duration(shiftDate.hours(), 'hours');
        const minutesDuration = moment.duration(shiftDate.minutes(), 'minutes');

        date = moment(date).add(hoursDuration);
        date = moment(date).add(minutesDuration);

        return date;
    }

    //TODO use formater
    private FormatTime(value: string): string {
        return value ? moment(value).format() : '';
    }

    private GetValueByFieldId(event: AgendaItemModel, fieldId: number): string {
        const fieldValue = _.find(event.Values, value => value.FieldId === fieldId);

        return fieldValue ?
            fieldValue.DisplayValue || fieldValue.Value :
            null;
    }

    private GetTranslatedValueByFieldId(event: AgendaItemModel, fieldId: number): string {
        const fieldValue = _.find(event.Values, value => value.FieldId === fieldId);

        return fieldValue ?
            fieldValue.TranslatedDisplayValue || fieldValue.TranslatedValue :
            null;
    }

    EventTitle(item: AgendaItemModel): string {
        const nameField = _.find(item.Values, value => value.FieldId === this._recordNameField.Id);
        return nameField ? nameField.TranslatedValue || nameField.Value : '';
    }

    get TodoEvents(): KnockoutObservableArray<AgendaItemModel> {
        return this._todoEvents;
    }

    get TodoEventName(): KnockoutObservable<string> {
        return this._todoEventName;
    }

    AddNewTodo() {
        const todoType = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Todo);

        if (todoType) {
            const screenData = new ScreenDataModel();
            const startDateControlData = new ControlDataModel();

            startDateControlData.FieldId = this._recordNameField.Id;
            startDateControlData.Value = this._todoEventName();
            screenData.ControlsData.push(startDateControlData);

            this.NewRecord(screenData, todoType.Id, null, DataModes.Default, null, null, true);
        }
    }

    async AddSpecialDay() {
        const specialDayType = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.SpecialDay);

        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        BlockUI.Block();
        screenManager.GetEditScreen({
            EntityId: this.FieldModel.EntityId,
            TableTypeId: specialDayType.Id
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then(screen => {
                screen.TransactionId = this.Screen.TransactionId;
                screen.ShowInModal();
            })
            .fail(err => new Notifier().Warning(err.message));
    }

    AddHoliday() {
        const screenData = new ScreenDataModel();
        const holidayType = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Holiday);
        this.NewRecord(screenData, holidayType.Id);
    }

    AddIllness() {
        const screenData = new ScreenDataModel();
        const illnessType = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Illness);
        this.NewRecord(screenData, illnessType.Id);
    }

    AddRecurrent() {
        const screenData = new ScreenDataModel();
        const recurrentType = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Recurrent);
        this.NewRecord(screenData, recurrentType.Id, null, DataModes.Default, null, null, true);
    }

    EditRecurrent(id: number) {
        const recurrentType = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Recurrent);
        this.EditRecord(id, recurrentType.Id);
    }

    RemoveTodoItem(todoEvent: AgendaItemModel) {
        const notifier = new Notifier();

        RecordStore.GetDeletionMode({EntityId: this.FieldModel.EntityId, RecordId: todoEvent.RecordId})
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (!result.IsSuccessfull) {
                    notifier.Failed(result.ErrorMessage || NOTIFICATIONS.ERROR_DELETING_RECORD);
                    return;
                }

                const deletionMode = result.ResultObject;

                if (deletionMode.OnDelete || deletionMode.OnDelete === 0) {
                    this.ShowDeletionModeDialog(deletionMode, todoEvent);
                } else {
                    this.ShowConfirmationDialog(todoEvent);
                }
            });
    }

    private ShowDeletionModeDialog(deletionModeModel: DeletionModeModel, todoEvent: AgendaItemModel) {
        const deletionModeDialog = new DeletionModeDialog({
            DeletionMode: deletionModeModel.OnDelete,
            HasCustomLifeStatus: deletionModeModel.HasCustomLifeStatus
        });

        deletionModeDialog.On(DELETION_MODE_DIALOG_EVENTS.DELETE_SELECTED, this, () => {
            this.UseDeleteDeletionMode(todoEvent);
        });

        deletionModeDialog.On(DELETION_MODE_DIALOG_EVENTS.DISABLE_SELECTED, this, () => {
            this.UseDisableDeletionMode(todoEvent);
        });

        deletionModeDialog.Show();
    }

    private ShowConfirmationDialog(todoEvent: AgendaItemModel) {
        const confirmationDialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.ARE_YOUR_SURE_TO_DELETE_RECORDS,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
            this.UseDeleteDeletionMode(todoEvent);
        });

        confirmationDialog.Show();
    }

    private UseDeleteDeletionMode(todoEvent: AgendaItemModel) {
        BlockUI.Block();

        const notifier = new Notifier();
        RecordStore.DeleteRecord({EntityId: this.FieldModel.EntityId, RecordId: todoEvent.RecordId})
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (!result.IsSuccessfull) {
                    notifier.Failed(result.ErrorMessage || NOTIFICATIONS.ERROR_DELETING_RECORD);
                    return;
                }

                if (result.Warnings && result.Warnings.length > 0) {
                    _.each(result.Warnings, (warning) => notifier.Warning(warning));
                    notifier.Success(NOTIFICATIONS.RECORD_UPDATED);
                } else {
                    notifier.Success(NOTIFICATIONS.RECORD_REMOVED);
                    this._todoEvents.splice(this._todoEvents.indexOf(todoEvent), 1);
                }
            })
            .fail(error => {
                notifier.Warning(error.message);
            });
    }

    private UseDisableDeletionMode(todoEvent: AgendaItemModel) {
        BlockUI.Block();

        const notifier = new Notifier();
        RecordStore.DeleteRecord({EntityId: this.FieldModel.EntityId, RecordId: todoEvent.RecordId, Disable: true})
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (!result.IsSuccessfull) {
                    notifier.Failed(result.ErrorMessage);
                    return;
                }

                notifier.Success(NOTIFICATIONS.RECORD_UPDATED);
            })
            .fail(error => {
                notifier.Warning(error.message);
            });
    }

    ShowToDoBlock() {
        this._isVisibleTodoBlock(true);
    }

    AddNewAppointment(startDate: string, durationMinutes: number = null, calendarUserId: number = null) {
        const duration = Agenda.ConvertDefaultAppointmentToDateTime(durationMinutes);

        const appointmentType = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Appointment);

        const screenData = new ScreenDataModel();
        const startDateControlData = new ControlDataModel();

        startDateControlData.FieldId = this._startDateField.Id;
        startDateControlData.Value = moment(startDate).format();

        const durationControlData = new ControlDataModel();

        durationControlData.FieldId = this._durationField.Id;
        durationControlData.Value = duration;

        screenData.ControlsData.push(startDateControlData);
        screenData.ControlsData.push(durationControlData);

        const exampleAppointmentId = this.GetForm() && this.GetForm().GetScreen() && this.GetForm().GetScreen().ExampleAppointmentId;

        this.NewRecord(screenData, appointmentType.Id, exampleAppointmentId, DataModes.Default, null, null, true, calendarUserId);
    }

    static ConvertDefaultAppointmentToDateTime(minutes: number) {
        let h: string | number = Math.floor(minutes / 60);
        let m: string | number = minutes % 60;

        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;

        return moment(`1970-01-01 ${h}:${m}:00`).format();
    }

    EditEvent(id: number) {
        const eventModel = _.find(this._allEvents, event => event.RecordId === id);
        if (eventModel) {
            const typeId = +this.GetValueByFieldId(eventModel, this._recordTypeField.Id);
            this.EditRecord(eventModel.RecordId, typeId);
        }
    }

    UpdateAppointment(id: number, startDate: moment.Moment, duration?: moment.Duration) {
        const notifier = new Notifier();
        const eventModel = _.find(this._allEvents, event => event.RecordId === id);

        if (eventModel) {
            const startDateFieldValue = _.find(eventModel.Values, value => value.FieldId === this._startDateField.Id);
            const timeBeforeFieldValue = _.find(eventModel.Values, value => value.FieldId === this._timeBeforeField.Id);
            const timeBefore = moment(timeBeforeFieldValue.Value);

            startDateFieldValue.Value = this.ShiftForwardDate(startDate, timeBefore).format();

            const durationFieldValue = _.find(eventModel.Values, value => value.FieldId === this._durationField.Id);
            let durationValue = moment(durationFieldValue.Value);

            if (duration) {
                const hoursDuration = moment.duration(duration.hours(), 'hours');
                const minutesDuration = moment.duration(duration.minutes(), 'minutes');

                durationValue = moment(durationValue).add(hoursDuration);
                durationValue = moment(durationValue).add(minutesDuration);
                durationFieldValue.Value = durationValue.format();
            }

            this.BlockUi();

            return AgendaDataStore.UpdateAgenda(eventModel, this.Screen.TransactionId)
                .always(() => {
                    this.UnBlockUi();
                })
                .then(() => {
                    this.Trigger('AGENDA_CHANGED');
                    notifier.Success(NOTIFICATIONS.EVENT_UPDATED);
                }).fail(error => notifier.Failed(error.message));
        }
    }

    CreateAppointmentFromPlanner(item: PlanningItemModel, button: PlannerItemWeekButton) {
        const notifier = new Notifier();
        const userId = this._selectedUsers.length === 1 ? this._selectedUsers[0] : UserManager.Instance.CurrentUser.Id;

        const params = {
            PlanningId: item.Id,
            StartDate: button.Date,
            UserId: userId
        };


        this.BlockUi();

        return AgendaDataStore.CreatePlanningAppointment(params, this.Screen.TransactionId)
            .always(() => {
                this.UnBlockUi();
            })
            .then(() => {
                this.Trigger('AGENDA_CHANGED');
                const notifier = new Notifier();
                notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                this.LoadData({ groupByResource: this._groupByResource() });
            }).fail(error => notifier.Failed(NOTIFICATIONS.SOMETHING_WENT_WRONG));
    }

    DistributeAppointmentsForPlanning(item: PlanningItemModel) {
        const notifier = new Notifier();
        const userId = this._selectedUsers.length === 1 ? this._selectedUsers[0] : UserManager.Instance.CurrentUser.Id;

        this.BlockUi();

        return AgendaDataStore.CreatePlanningAppointments(item.Id, this.Screen.TransactionId, userId)
            .always(() => {
                this.UnBlockUi();
            })
            .then(() => {
                this.Trigger('AGENDA_CHANGED');
                const notifier = new Notifier();
                notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                this.LoadData( { groupByResource: this._groupByResource() });
            }).fail(error => notifier.Failed(NOTIFICATIONS.SOMETHING_WENT_WRONG));
    }

    BookFromPlanner(item: PlanningItemModel, button: PlannerItemWeekButton) {
        const notifier = new Notifier();

        const params = {
            PlanningId: item.Id,
            StartDate: button.Date,
            AgendaTableId: this.FieldModel.EntityId
        };

        this.BlockUi();

        return AgendaDataStore.BookPlanning(params, this.Screen.TransactionId)
            .always(() => {
                this.UnBlockUi();
            })
            .then(() => {
                this.Trigger('AGENDA_CHANGED');
                const notifier = new Notifier();
                notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                this.LoadData({ groupByResource: this._groupByResource() });
            }).fail(error => notifier.Failed(error.message));
    }

    AcceptAppointment(appointment) {
        appointment.Accepting(true);
        const screen = this.GetForm().GetScreen();
        const isSubjectScreen = screen.IsSubjectScreen();

        AgendaDataStore.AcceptAppointment({
            AgendaTableId: this.FieldModel.EntityId,
            AppointmentId: appointment.Id,
            ScreenSubjectEntityId: isSubjectScreen && screen.GetEntityId(),
            ScreenSubjectRecordId: isSubjectScreen && screen.GetRecordId()
        }, this.Screen.TransactionId)
            .then(result => {
                if (result.IsSuccessfull) {
                    new Notifier().Success(NOTIFICATIONS.BOOKED_TIME_RECORD_WAS_CREATED_SUCCESSFULLY);
                    this.Trigger('AGENDA_CHANGED');
                    this.LoadData({ groupByResource: this._groupByResource() });
                } else {
                    new Notifier().Failed(result.ErrorMessage);
                    appointment.Accepting(false);
                }
            })
            .fail(() => {
                appointment.Accepting(false);
                new Notifier().Failed('Error accepting appointment');
            });
    }

    StartDrag(item: AgendaItemModel, evt, ui) {
        this._todoToAppointmentItem = item;
    }

    ConvertTodoToAppointment(eventModel: AgendaItemModel, startDate: string) {
        const notifier = new Notifier();
        const appointmentDuration = GlobalManager.Instance.GetGlobal(GLOBALS.APPOINTMENT_DURATION);
        let appointmentDurationNumber = parseInt(appointmentDuration);

        if (isNaN(appointmentDurationNumber)) {
            appointmentDurationNumber = DEFAULT_APPOINTMENT_DURATION;
        }

        const cloneEventModel = _.clone(eventModel);

        const duration = Agenda.ConvertDefaultAppointmentToDateTime(appointmentDurationNumber);
        const startDateFieldValue = _.find(cloneEventModel.Values, value => value.FieldId === this._startDateField.Id);

        startDateFieldValue.Value = startDate;

        const durationFieldValue = _.find(cloneEventModel.Values, value => value.FieldId === this._durationField.Id);

        durationFieldValue.Value = duration;

        const appointmentType = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Appointment);
        const eventTypeFieldValue = _.find(cloneEventModel.Values, value => value.FieldId === this._recordTypeField.Id);

        eventTypeFieldValue.Value = String(appointmentType.Id);

        this.BlockUi();

        let createAppointmentFromTodoModel: ICreateAppointmentFromTodoRequestModel = {
            AgendaEntityId: this.FieldModel.EntityId,
            TodoItemId: eventModel.RecordId,
            Starting: startDate,
            Duration: duration,
            RemoveTodo: this._checkedRemoveToDo(),
            AppointmentAttendeesId: this.GetSelectedAppointmentAttendees()
        };

        AgendaDataStore.CreateAppointmentFromTodo(createAppointmentFromTodoModel, this.Screen.TransactionId)
            .always(() => {
                this.UnBlockUi();
            })
            .then((result) => {
                this.Trigger('AGENDA_CHANGED');
                if (this._checkedRemoveToDo()) {
                    this._todoEvents.splice(this._todoEvents.indexOf(eventModel), 1);
                    this._checkedRemoveToDo(false);
                }

                cloneEventModel.RecordId = result.Id;

                notifier.Success(NOTIFICATIONS.EVENT_UPDATED);
                let fullCalendarEventModel = this.GetFullCalendarEventModel(cloneEventModel);
                this._calendarEvents.push(fullCalendarEventModel);
                this.LoadData({ groupByResource: this._groupByResource() })
                    .then((result) => {
                        this.EditRecord(cloneEventModel.RecordId, appointmentType.Id);
                    });
            })
            .fail(error => new Notifier().Failed(error.message));
    }

    private IsUserAllowedToCreate() {
        return AgendaDataStore.GetUserAllowance({
            TableId: this.FieldModel.EntityId,
            SecurityWord: TABLE_SECURITY_WORDS.CREATE
        });
    }

    private InitDateTimePicker(element: JQuery) {
        let self = this;
        //Date picker on click of the current period
        const fullCalendarBox = element.parent().parent(); //FullCalendar Obj
        const headerToolbar = fullCalendarBox.find('div.fc-toolbar.fc-header-toolbar');
        const centerBlock = headerToolbar.find('div.fc-center');
        const headerDateBtn = centerBlock.find('h2'); //find "h2" element
        headerDateBtn.append(`<input type='text' class="form-control" style="display: none;" />`);  //added input, need to work dateTimePicker
        headerDateBtn.addClass('activeClick');

        if (!headerDateBtn.data("DateTimePicker")) {        //default start DateTimePicker

            headerDateBtn.datetimepicker({
                minDate: '1990-01-01'
                // defaultDate: this._selectedStartDate
            });

            headerDateBtn.on('dp.change', function (e) {
                fullCalendarBox.fullCalendar('gotoDate', e.date);
            });

            function alignDateTimePicker() {
                headerDateBtn.find('.bootstrap-datetimepicker-widget').css(
                    centerBlock.width() === headerToolbar.width()
                        ? {left: '50%', transform: 'translateX(-50%)'}
                        : {transform: ''}
                );
            }

            headerDateBtn.on('dp.show', () => {
                alignDateTimePicker();

                $(window).on('resize', alignDateTimePicker);
            });

            headerDateBtn.on('dp.hide', () => {
                $(window).off('resize', alignDateTimePicker);
            });

            $(headerDateBtn).on('click', function () {
                let currentDate = moment(self._selectedStartDate);
                if (self._viewRenderMode == FULL_CALENDAR_VIEW.MONTH) {            // DateTimePicker new options if(MONTH)
                    headerDateBtn.data("DateTimePicker").date(fullCalendarBox.fullCalendar('getDate').format(DATE_FORMATS.MONTH_AND_YEAR.Format));
                } else {                                                            // DateTimePicker default options
                    headerDateBtn.data("DateTimePicker").date(currentDate);
                }
                $(this).data("DateTimePicker").toggle();
            });
            $(headerDateBtn).on('mouseleave', function () {
                $(this).data("DateTimePicker").hide();
            });
        }

        switch (self._viewRenderMode) {
            case FULL_CALENDAR_VIEW.AGENDA_FIVE_DAY :                               // DateTimePicker new options if(AGENDA_FIVE_DAY)
                headerDateBtn.data("DateTimePicker") && headerDateBtn.data("DateTimePicker").options({
                    viewMode: 'days',
                    locale: FullCalendarCultures.Get(FormatConverter.GetLocale()),
                    format: this.GetShortDateFormat(),
                    daysOfWeekDisabled: [0, 6],
                });
                break;
            case FULL_CALENDAR_VIEW.MONTH :                                         // DateTimePicker new options if(MONTH)
                headerDateBtn.data("DateTimePicker") && headerDateBtn.data("DateTimePicker").options({
                    viewMode: 'months',
                    locale: FullCalendarCultures.Get(FormatConverter.GetLocale()),
                    format: this.GetMonthAndYearFormat(),
                });
                break;
            default :                                                               // DateTimePicker default options
                headerDateBtn.data("DateTimePicker") && headerDateBtn.data("DateTimePicker").options({
                    viewMode: 'days',
                    locale: FullCalendarCultures.Get(FormatConverter.GetLocale()),
                    format: this.GetShortDateFormat(),
                    daysOfWeekDisabled: false,
                });
                break;
        }
    }

    private GetFullCalendarOptions(): any {
        const self = this;

        if (this._appointmentAttendees() && this._appointmentAttendees().Users.length && !self._groupByResource()) {
            self._groupByResource(true)
        }

        this.SetLastDate();

        return {
            defaultDate: this._selectedStartDate,
            views: {
                agendaFiveDay: {
                    type: 'agendaWeek',
                    weekends: false,
                    groupByResource: self._groupByResource(),
                    columnFormat: 'ddd M/D'
                },
                agendaWeek: {
                    groupByResource: self._groupByResource(),
                    columnFormat: 'ddd M/D'
                },
                month: {
                    eventLimit: 4,
                    groupByResource: false
                },
                listWeek: {
                    groupByResource: false,
                    noEventsMessage: LABELS.AGENDA_NO_EVENTS_TO_DISPLAY
                },
                timelineWeek: {
                    resourceLabelText: LABELS.AGENDA_USERS
                }

            },
            allDaySlot: true,
            firstDay: 1,
            locale: FullCalendarCultures.Get(FormatConverter.GetLocale()),
            header: {
                center: 'prev, title, next',
                right: 'today, month, agendaWeek, agendaFiveDay, agendaDay, listWeek, timelineWeek',
                left: ''
            },
            buttonIcons: {
                prev: 'left-triangle',
                next: 'right-triangle',
                prevYear: 'left-double-arrow',
                nextYear: 'right-double-arrow'
            },
            buttonText: {
                today: LABELS.AGENDA_TODAY,
                month: LABELS.AGENDA_MONTH,
                agendaWeek: LABELS.AGENDA_WEEK,
                agendaFiveDay: LABELS.AGENDA_WORK_WEEK,
                agendaDay: LABELS.AGENDA_DAY,
                listWeek: LABELS.AGENDA_LIST,
                timelineWeek: LABELS.AGENDA_TIMELINE_WEEK
            },
            scrollTime: '08:00:00',
            slotEventOverlap: false,
            timeFormat: ' ',
            defaultView: MobileChecker.IsMobile() ? FULL_CALENDAR_VIEW.AGENDA_DAY : (this._isReturned ? this._viewRenderMode : FULL_CALENDAR_VIEW.AGENDA_FIVE_DAY),
            slotMinutes: 15,
            editable: !this._isReadonly,
            droppable: true,
            forceEventDuration: true,
            defaultTimedEventDuration: '00:30:00',
            selectable: !MobileChecker.IsMobile(),
            weekNumbers: true,
            navLinks: true,
            longPressDelay: 1000,
            eventLongPressDelay: 1000,
            resources: callback => callback(this._owners()),
            eventRenderWait: 300,

            viewRender: (view, element) => {
                const {
                        MONTH,
                        AGENDA_WEEK,
                        AGENDA_FIVE_DAY,
                        AGENDA_DAY
                } = FULL_CALENDAR_VIEW;
                const viewName = view.name;
                const isAgendaDayOrMonth = [AGENDA_DAY, MONTH].includes(viewName);
                const isAgendaFiveDayOrWeek = [AGENDA_FIVE_DAY, AGENDA_WEEK].includes(viewName);
                const groupByResource = self._groupByResource();

                const todoBlock = document.getElementById('external-events');
                const agendaCalendar = document.getElementById('agendaCalendar');
                if (agendaCalendar && todoBlock) {
                    todoBlock.style.maxHeight = `${agendaCalendar.offsetHeight}px`;
                }

                if(isAgendaDayOrMonth) {
                    self._groupByResource(false);
                } else if (isAgendaFiveDayOrWeek && !groupByResource && self._isInteractionWithUserSelect()) {
                    self._groupByResource(true);
                }

                if (isAgendaFiveDayOrWeek && groupByResource && self._isInteractionWithUserSelect()) {
                    const days = viewName === AGENDA_FIVE_DAY ? 5 :
                        viewName === AGENDA_WEEK ? 7 : null;
                    if (days) {
                        let minColumnWidth = this._isSmallAppointments ? 20 : 100;
                        self._timeoutId = setTimeout(() => {
                            self.SetCellMinWidthOfDays(minColumnWidth, days);
                        }, 200);
                        // console.log('timeoutId - '+self._timeoutId)
                    }
                }

                if (groupByResource && (viewName === AGENDA_FIVE_DAY || viewName === AGENDA_WEEK)) {
                    element.find('.fc-day-grid-container').hide(); // Hide the default grid
                }

                if (viewName === MONTH) { // add .fc-disabled-day css class
                    $('#agendaCalendar').find('.fc-day').each(function() {
                        var date = $(this).data('date');
                        var day = moment(date).day();
                        if (day === 0 || day === 6) {
                            $(this).addClass('fc-disabled-day');
                        }
                    });
                } else {
                    $('#agendaCalendar').find('.fc-day').removeClass('fc-disabled-day');
                }

                this._currentDay = moment(view.start._i).format(DATE_FORMATS.SHORT_DATE.Format);
                this._viewRenderMode = view.type;
                this._agendaViewContainer = element;

                const startDate = FormatConverter.CorrectTimezone(moment(view.start._i).startOf('day').format());
                const endDate = FormatConverter.CorrectTimezone(moment(view.end._i).subtract(1, "days").endOf('day').format());

                if (!this._isReturned) {
                    if (this._selectedStartDate !== startDate || this._selectedEndDate !== endDate) {
                        this.Clean(element);
                        this._selectedStartDate = startDate;
                        this._selectedEndDate = endDate;

                        if (this._selectedUsers.length !== 0 && (this.SelectUserIsInitialized() || !this.SelectUserWillProvideEventOnLoad())) {
                            this.LoadData({ groupByResource: self._groupByResource() });
                        }
                    }
                } else {
                    this.Clean(element);
                    this._selectedStartDate = startDate;
                    this.Trigger('AGENDA_CHANGED');
                    this.LoadData();
                }

                this.SetDate(this._controlId, startDate);
                this._isReturned = false;
                this.InitDateTimePicker(element);
            },

            viewDestroy: (view, element)=> {
                self.RemoveDuplicatesNonBusiness();
            },

            dayClick: (start: moment.Moment, jsEvent, view, resource) => {
                if (!MobileChecker.IsMobile()) return;
                const calendarUserId = resource && resource.id && Number(resource.id);
                this.AddNewAppointment(start.format(), this.GetAppointmentDuration(), calendarUserId);
            },

            drop: function (date, jsEvent, ui) {
                    if (date._ambigTime && (self._viewRenderMode !== FULL_CALENDAR_VIEW.MONTH)) { //prohibit drag and drop from hours to all-day
                        return false;
                    }
                    let droppedEvent = $(this).data("eventObj");
                    const eventId = $(this).data("event-id");

                    let allDayArr = _.find(self._calendarEvents(), (event_) => event_.allDay == true && droppedEvent && event_.eventRecordId === eventId);
                    if (allDayArr) { //prohibit drag and drop from all-day to hours
                        return false;
                    }
                    if (self._todoToAppointmentItem && !jsEvent.ctrlKey) {
                        self.ConvertTodoToAppointment(self._todoToAppointmentItem, FormatConverter.ShiftTimeZone(FormatConverter.CorrectTimezone(date.format()), true));
                        self._todoToAppointmentItem = null;
                    }
                    if (jsEvent.ctrlKey) {
                        self.IsUserAllowedToCreate()
                            .then((res) => {
                                const isMonthView = self._viewRenderMode === FULL_CALENDAR_VIEW.MONTH;
                                if (res) {
                                    isMonthView && (date._ambigTime = false);
                                    const droppedEvent = $(this).data("eventObj");
                                    let startDate = !isMonthView ? moment(date) : moment(date).set({
                                        hours: droppedEvent.dateProfile.start.hours(),
                                        minutes: droppedEvent.dateProfile.start.minutes(),
                                        seconds: droppedEvent.dateProfile.start.seconds()
                                    });
                                    const eventId = $(this).data("event-id");
                                    const recordId = +eventId;
                                    const appointment: TableTypeModel = _.find(self._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Appointment);
                                    const starting = FormatConverter.ShiftTimeZone(FormatConverter.CorrectTimezone(startDate.format()), true);
                                    self.CopyRecord(null, appointment.Id, recordId, DataModes.CopyWithRelations, null, starting);
                                } else {
                                    const notifier = new Notifier();
                                    notifier.Failed(NOTIFICATIONS.NOT_ALLOWED_ACTION);
                                }
                            })
                            .fail(err => {
                                const notifier = new Notifier();
                                notifier.Failed(err.message);
                            });
                        (self._viewRenderMode === FULL_CALENDAR_VIEW.MONTH) && (date._ambigTime = true);
                    }
                },

            eventDragStart(event) {
                if (self._disableDropEvtsBtwnDiffRes) {
                    // Store original resource ID
                    if (self._groupByResource()) {
                        // console.log('eventDragStart originalResourceId -'+event.originalResourceId()+'  event.resourceId -'+event.resourceId);
                        event.originalResourceId(event.resourceId);
                    }
                }

                self._enableTooltip = false;

                const eventId = self._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? event.eventRecordId : event.id;
                const appointment = _.find(self._appointmentEvents, (event_) => event_.RecordId === eventId);
                if (!appointment) {
                    const notifier = new Notifier();
                    notifier.Warning(NOTIFICATIONS.EDIT__ONLY_APPOINTMENT_EVENTS);
                    return;
                }

                const durationFieldValue = _.find(appointment.Values, value => value.FieldId === self._durationField.Id);
                const hasDuration = durationFieldValue && durationFieldValue.Value;
                if (!hasDuration) {
                    new Notifier().Warning('Appointment does not have duration');
                    return;
                }

            },

            eventDrop: (event, delta, revertFunc, jsEvent, ui, view) => {
                const {
                    MONTH,
                    AGENDA_WEEK,
                    AGENDA_FIVE_DAY,
                    AGENDA_DAY
                } = FULL_CALENDAR_VIEW;
                const agendaDayOrWeek = [AGENDA_FIVE_DAY, AGENDA_WEEK].includes(view.name);

                if (self._disableDropEvtsBtwnDiffRes && self._groupByResource() && agendaDayOrWeek) {
                    let originalResourceId = event.originalResourceId();
                    let newResourceId = $(jsEvent.target).closest('.fc-event').data('resource-id');
                    // console.log(`originalResourceId - ${+originalResourceId}`)
                    // console.log(`newResourceId - ${newResourceId}`)

                    if (!newResourceId || +originalResourceId !== newResourceId) {
                        revertFunc(); // revert the drop
                        new Notifier().Warning('Cannot move events between different resources');
                        return
                    }
                }

                self._enableTooltip = true;
                if (event.editable) {
                    const eventId = self._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? event.eventRecordId : event.id;

                    this.UpdateAppointment(eventId, moment(event.start._i))
                        .then(() => {
                            let currentEvent = null;
                            if (self._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK) {
                                currentEvent = _.find(this._calendarEvents(), (event_) => event_.eventRecordId === eventId);
                            } else {
                                currentEvent = _.find(this._calendarEvents(), (event_) => event_.id === eventId);
                            }

                            if (currentEvent) {
                                currentEvent.start = event.start.format();
                                currentEvent.end = event.end.format();

                                let start = moment(event.start.format()).format("MM-DD-YYYY");
                                let current = moment().format("MM-DD-YYYY");

                                if (moment(start).isBefore(current) ||
                                    moment(start).isSame(current)) {

                                    currentEvent.possibleToAccept(true);
                                } else {

                                    currentEvent.possibleToAccept(false);
                                }
                            }
                        })
                        .fail(() => revertFunc());
                }
            },

            eventAllow: function (dropLocation, event) {
                if (event.allDay) { //prohibit drag and drop from hours to all-day
                    return false;
                }
                const eventId = self._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? event.eventRecordId : event.id;
                const appointment: AgendaItemModel = _.find(self._appointmentEvents, (event_: AgendaItemModel) => event_.RecordId === eventId);
                if (!appointment) return false;

                const durationFieldValue = _.find(appointment.Values, value => value.FieldId === self._durationField.Id);
                const hasDuration = durationFieldValue && durationFieldValue.Value;

                if (!hasDuration) return false;
            },

            eventClick: (event) => {
                if (!this._isReadonly && (event.editable || event.editableWithDisabledLifeStatus)) {
                    const eventId = this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? event.eventRecordId : event.id;
                    this.EditEvent(eventId);
                }
            },

            eventResizeStart: (event) => {
                self._enableTooltip = false;

                if (event.Tooltip) {
                    event.Tooltip.Destroy();
                }
            },

            eventResize: (event, delta, revertFunc) => {
                if (event.editable) {
                    const eventId = this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? event.eventRecordId : event.id;
                    this.UpdateAppointment(eventId, moment(event.start._i), delta)
                        .fail(() => revertFunc());
                }
            },

            select: (start: moment.Moment, end: moment.Moment, jsEvent, view, resource) => {
                    if (this._isReadonly) return;

                    const calendarUserId = resource && resource.id && Number(resource.id);

                    if (view.name === FULL_CALENDAR_VIEW.MONTH) {
                        this.AddNewAppointment(start.format(), this.GetAppointmentDuration(), calendarUserId);
                        return;
                    }
                    const duration = end.diff(start, 'minutes');
                    if (duration !== DEFAULT_APPOINTMENT_DURATION) {
                        if (end.diff(start, 'days') === 0) {
                            this.AddNewAppointment(start.format(), duration, calendarUserId);
                            return;
                        }

                        new Notifier().Warning(NOTIFICATIONS.SELECT_ONLY_WITHIN_DAY);
                        return;
                    }

                    this.AddNewAppointment(start.format(), this.GetAppointmentDuration(), calendarUserId);
                },

            eventResizeStop(event) {
                self._enableTooltip = true;
            },

            eventRender: (event, element: JQuery, view) => {
                element.attr('data-event-id', event.eventRecordId);
                if (self._disableDropEvtsBtwnDiffRes) {
                    element.attr('data-resource-id', event.resourceId);
                    // console.log('eventRender data-resource-id - ' + event.resourceId)
                }

                if (event.lifeStatusName === AGENDA_LIFE_STATUSES.Denied) {
                    return false;
                }

                _.each(event.links, (link: any) => {
                    link.IconComponent = this.GetLinkedIcon(link.Icon);
                });

                if (event.users) {
                    event.users.IconComponent = this.GetLinkedIcon(event.users.Icon);
                }

                const startDate = moment(event.start);

                const beforeTime = moment(event.timeBefore).format(DATE_FORMATS.TIME.Format),
                    stringParts = beforeTime.split(':'),
                    resHours = +stringParts[0],
                    resMinutes = +(parseInt(stringParts[1]));

                startDate.add({hours: resHours, minutes: resMinutes});

                const eventId = this._viewRenderMode === FULL_CALENDAR_VIEW.AGENDA_TIME_LINE_WEEK ? event.eventRecordId : event.id;

                const startTime = this._viewRenderMode === FULL_CALENDAR_VIEW.MONTH && (!eventId || event.allDay) ? '' : this._viewRenderMode === FULL_CALENDAR_VIEW.MONTH ? startDate.format(DATE_FORMATS.TIME.Format) : moment(event.start).format(DATE_FORMATS.TIME.Format);
                const visibleBookingsWeekButton = self._selectedUsers.length === 0 ||
                    (self._selectedUsers.length == 1 && self._selectedUsers[0] === UserManager.Instance.CurrentUser.Id);

                const startListTime = moment(event.start).format('hh:mm a');
                const endListTime = moment(event.end).format('hh:mm a');
                const listTime = event.allDay ? `all-day` : startTime ? `${startListTime} - ${endListTime}` : startTime || null;

                const viewModel = {
                    Id: eventId,
                    BackgroundColor: event.color ? event.color : event.placeColor,
                    TimeBefore: event.timeBefore,
                    Title: event.title,
                    Owner: event.owner,
                    TimeAfter: event.timeAfter,
                    Description: event.description,
                    TimeBeforeHeight: ko.observable({height: event.timeBeforeHeigth - 2 + 'px'}),
                    TimeAfterHeight: ko.observable({height: event.timeAfterHeigth - 2 + 'px'}),
                    Accepting: ko.observable(false),
                    Accepted: event.accepted,
                    PossibleToAccept: event.possibleToAccept,
                    RecurrentRecordId: event.recurrentRecordId,
                    Links: event.links,
                    Users: event.users || [],
                    AppointmentsWeekButtons: event.appointmentsWeekButtons,
                    AppointmentsCanBeDistributed: _.any(event.appointmentsWeekButtons, (button: any) => {
                        const dayNumber = moment(button.Date).day();
                        return dayNumber > 0 && dayNumber < 6 && !button.Disabled;
                    }),
                    BookingsWeekButtons: event.bookingsWeekButtons,
                    Distribute: LABELS.DISTRIBUTE,
                    Event: event,
                    Agenda: this,
                    ActionToggle: ko.observable(false),
                    ShowAppointmentsWeekMenu: ko.observable(false),
                    ShowBookingsWeekMenu: ko.observable(false),
                    VisibleBookingsWeekButton: visibleBookingsWeekButton,
                    Editable: event.editable || event.editableWithDisabledLifeStatus,
                    StartTime: startTime,
                    Country: event.country,
                    SpecialDay: event.specialDay,
                    IsIllness: event.isIllness,
                    IsHoliday: event.isHoliday,
                    IsHolidayIcon: event.isHolidayIcon,
                    HolidayTooltip: !!event.holidayTooltip ? event.holidayTooltip : null,
                    EnableTooltip: this._enableTooltip,
                    ListModeTime: listTime,
                    ViewType: view.type
                };

                event.agenda = this;
                self._viewModels.push(viewModel);

                self.RenderAllCustomEvents(event, element, viewModel);
            },

            eventAfterRender: (event, element, view) => {
                self.ToggleSmallMode(event, element, view);
                self.EventTitleFix(element);

                if (self._enableTooltip) {
                    self.AgendaLinksEllipsisTooltip(event, element, view);
                }
            },

            eventDestroy: (event, element, view) =>{

            }
        };
    }

    RenderAllCustomEvents(event, element, viewModel) {
        const {
            MONTH,
            AGENDA_WEEK,
            AGENDA_FIVE_DAY,
            AGENDA_DAY,
            AGENDA_LIST_WEEK
        } = FULL_CALENDAR_VIEW;
        const isAgendaListWeek = [AGENDA_LIST_WEEK].includes(viewModel.ViewType);

        if (isAgendaListWeek) {
            element.find('.fc-widget-content').remove();
        }

        element.find('.fc-content').remove();
        element.find('.fc-bg').remove();
        ko.cleanNode(element[0]);
        element[0].setAttribute('data-bind', 'allowBindings: false');

        const template = (ko as any).renderTemplateX(this.GetEventTemplate(viewModel), viewModel);

        element.prepend(template);
        if (event.editable) {
            element.addClass("fc-appointment");
        }

        if (event.isSpecial) {
            let tooltipContent = event.title;

            if (event.country) {
                tooltipContent += `: ${event.country}`;
            }

            event.Tooltip = new Tooltip(element, tooltipContent, {
                position: {x: 'right', y: 'center'},
                outside: 'x'
            });
            event.Tooltip.AddClass('ellipsis-tooltip isSpecialTooltip');
        }
    }

    GetLinkedIcon(iconModel) {
        const linkedIconModel = new IconModel();

        linkedIconModel.FontName = iconModel.FontName;
        linkedIconModel.Name = iconModel.Name;
        linkedIconModel.Image = iconModel.Image;
        linkedIconModel.IsIcon = iconModel.IsIcon;
        linkedIconModel.IsImage = iconModel.IsImage;

        const linkedIcon = new Icon(linkedIconModel);

        return linkedIcon;
    }

    IsUseDefaultDuration(calendarView: string): boolean {
        return calendarView === FULL_CALENDAR_VIEW.MONTH;
    }

    BlockUi() {
        BlockUI.Block({Target: this._el});
    }

    GetEventTemplate(viewModel) {
        if (viewModel.Event.planning) {
            return PlanningTemplate;
        }

        const {
            MONTH,
            AGENDA_WEEK,
            AGENDA_FIVE_DAY,
            AGENDA_DAY,
            AGENDA_LIST_WEEK,
            AGENDA_TIME_LINE_WEEK
        } = FULL_CALENDAR_VIEW;
        const agendaMonth = [MONTH].includes(viewModel.ViewType);
        const agendaListWeek = [AGENDA_LIST_WEEK].includes(viewModel.ViewType);
        const agendaTimeLineWeek = [AGENDA_TIME_LINE_WEEK].includes(viewModel.ViewType);

        if (agendaListWeek) {
            return EventListTemplate;
        }

        if (agendaTimeLineWeek) {
            return EventTimeTemplate;
        }

        return agendaMonth ? EventMonthTemplate : EventTemplate;
    }

    UnBlockUi() {
        BlockUI.Unblock(this._el);
    }

    EventTitleFix(element: JQuery){
        const startDateElem = element.find('.start-date'),
            eventContent = element.find('.event-content'),
            descriptionButton = element.find('.custom-agenda-event__descriptionButton');

        if (startDateElem.outerHeight() >= 60 && descriptionButton.length ||
            startDateElem.outerHeight() >= 30 && !descriptionButton.length) {
            eventContent.css({
                "width": "100%"
            });
        }

    }

    AgendaLinksEllipsisTooltip(event, element: JQuery, view) {
        const {
            MONTH,
            AGENDA_WEEK,
            AGENDA_FIVE_DAY,
            AGENDA_DAY,
            AGENDA_LIST_WEEK,
            AGENDA_TIME_LINE_WEEK
        } = FULL_CALENDAR_VIEW;
        const isAgendaListOrTimeWeek = [AGENDA_LIST_WEEK, AGENDA_TIME_LINE_WEEK].includes(view.type);

        const startDateElem = element.find('.start-date'),
            eventMain = element.find('.event-main'),
            eventContent = element.find('.event-content'),
            agendaLinksElem = element.find('.agenda-links-container'),
            endDateElem = element.find('.end-date'),
            elemEventHeight = element.outerHeight() - startDateElem.outerHeight();

        const allContentHeight = eventMain.outerHeight() + agendaLinksElem.outerHeight(); //startDateElem.outerHeight() + eventMain.outerHeight() + agendaLinksElem.outerHeight() + endDateElem.outerHeight();

        if ( allContentHeight > elemEventHeight || (this._groupByResource() && this._isSmallAppointments) || isAgendaListOrTimeWeek ){
            let _self = this;
            if ( this.GetLinkedUsers(event.links).length ) {
                event.LinksEllipsisTooltip = new Tooltip(element, LinksTooltipTemplate as any,
                    {
                        position: {x: 'left', y: 'center'},
                        outside: 'x',
                        zIndex: ZIndexManager.Instance.NextValue,
                        onCreated: () => {
                            ko.cleanNode(event.LinksEllipsisTooltip._jbox.content[0]);
                            ko.applyBindings(event, event.LinksEllipsisTooltip._jbox.content[0]);
                        },
                        onClose: ()=> {

                        }
                    });
                event.LinksEllipsisTooltip.AddClass('agenda-links-ellipsis-tooltip jBox-padding-5px');
            }
        }
    }

    ToggleSmallMode(event, element: JQuery, view) {
        let actionMenuBox = element.find('.actionMenuBox'),
            customAgendaEvent = element.find('.custom-agenda-event'),
            descriptionButton = element.find('.custom-agenda-event__descriptionButton'),
            outerHeight = element.outerHeight();

        if (outerHeight <= 30) {
            customAgendaEvent.addClass('custom-agenda-event__smallMode');
            actionMenuBox.addClass('smallMode');
            descriptionButton.addClass('smallMode');
        } else if (outerHeight <= 32) {
            descriptionButton.addClass('smallMode_V2');
            customAgendaEvent.addClass('custom-agenda-event__smallMode_V2');
        }
    }

    CopyRecord(
        screenData: ScreenDataModel,
        tableTypeId: number,
        exampleRecordId: number = null,
        dataMode: DataModes = DataModes.Default,
        subjectLifestatusId: number = null,
        starting: string = null) {
        BlockUI.Block();

        AgendaDataStore
            .CopyRecord({
                TableId: this.FieldModel.EntityId,
                TableTypeId: tableTypeId,
                RecordId: exampleRecordId,
                Starting: starting,
                AppointmentAttendeesId: this.GetSelectedAppointmentAttendees()
            })
            .then((result: any) => {
                this.Trigger('AGENDA_CHANGED');
                const notifier = new Notifier();
                notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                this.LoadData({ groupByResource: this._groupByResource() });
            })
            .fail(error => new Notifier().Failed(error.message))
            .always(() => {
                BlockUI.Unblock();
            });
        return;
    }

    async NewRecord(
        screenData: ScreenDataModel,
        tableTypeId: number,
        exampleRecordId: number = null,
        dataMode: DataModes = DataModes.Default,
        subjectLifestatusId: number = null,
        defaultValues?: Array<{ FieldName: string, FieldValue: string, Type: string }>,
        shouldLinkAppointmentAttendees: boolean = false,
        calendarUserId: number = null,
        linkToSource: boolean = false,
        aliasSuffix: string = '') {
        BlockUI.Block();

        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        screenManager.GetEditScreen(
            {
                EntityId: this.FieldModel.EntityId,
                TableTypeId: tableTypeId,
                RecordId: exampleRecordId,
                LoadAsExample: exampleRecordId > 0,
                SubjectLifestatusId: subjectLifestatusId,
                DataMode: dataMode,
                AliasSuffix: aliasSuffix
            })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                screen.TransactionId = this.Screen.TransactionId;

                if (screenData) {
                    screenData.ControlsData.forEach(controlData => {
                        const screenControl = screen.GetControlBy(control => control.HasOneField() && control.GetFieldModel().Id === controlData.FieldId);
                        if (screenControl) {
                            screenControl.SetDefaultValue({
                                SubjectRecordId: screen.GetRecordId(),
                                SubjectEntityId: screen.GetEntityId(),
                                Data: controlData,
                                RecordSpecsModel: new RecordSpecsModel()
                            })
                        }
                    });
                }

                let relatedRecordId = this.GetForm().GetScreen().GetRecordId();
                screen.IsDataFromExample = exampleRecordId > 0;
                screen.LinkToSource = linkToSource;

                const linkList = screen.GetControl(CONTROL_TYPES.LinkList) as LinkList;
                if (linkList) {
                    screen.On('LINK_LIST_DATA_LOADED', this, () => {
                        if (relatedRecordId > 0) {
                            let relatedEntityId = this.GetForm().GetScreen().GetEntityId();
                            screen.AddInnerRelation(relatedEntityId, relatedRecordId);
                        }

                        if (shouldLinkAppointmentAttendees) {
                            this.LinkAppointmentAttendees(linkList, calendarUserId);
                        }
                    });
                }

                screen.On('RECORD_SAVED', this, (eventArgs) => {
                    this.Screen.Trigger('RECORD_CREATED', { RecordId: eventArgs.data.RecordId });
                    this.Trigger('AGENDA_CHANGED');
                    const notifier = new Notifier();
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                    this.LoadData({ groupByResource: this._groupByResource() });
                });

                screen.On('COPY', this, (eventArgs) => {
                    const copyTableType = eventArgs.data.copyToType || tableTypeId;
                    this.NewRecord(null, copyTableType, eventArgs.data.recordId, eventArgs.data.dataMode, null, null, false, null, eventArgs.data.linkToSource, eventArgs.data.aliasSuffix);
                });

                if (defaultValues) {
                    _.forEach(defaultValues, (item) => {
                        const control = screen.GetControlByFieldName(item.FieldName, item.Type);
                        let startingModel = new ControlDataModel();
                        startingModel.Value = item.FieldValue;
                        control.SetDefaultValue({
                            SubjectRecordId: screen.GetRecordId(),
                            SubjectEntityId: screen.GetEntityId(),
                            Data: startingModel,
                            RecordSpecsModel: new RecordSpecsModel()
                        });
                    });
                }

                screen.ShowInModal();

                if (!linkList && shouldLinkAppointmentAttendees && this.GetSelectedAppointmentAttendees().length > 0) {
                    new Notifier().Warning(NOTIFICATIONS.ATTENDEES_CANNOT_BE_ADDED_DUE_TO_MISSING_LINK_LIST);
                }
            })
            .fail(err => new Notifier($(this._el)).Warning(err.message));
    }

    async EditRecord(recordId: number, tableTypeId: number) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        BlockUI.Block();

        LockManager.Instance.TryLock(this.FieldModel.EntityId, recordId)
            .then(() => {
                screenManager.GetEditScreen({
                    EntityId: this.FieldModel.EntityId,
                    TableTypeId: tableTypeId,
                    RecordId: recordId
                })
                    .always(() => BlockUI.Unblock())
                    .then((screen: EditScreen) => {
                        screen.TransactionId = this.Screen.TransactionId;
                        LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
                            if (eventArgs.data.TableId === this.FieldModel.EntityId && eventArgs.data.RecordId === recordId) {
                                screen.Close();
                            }
                        });
                        screen.On('MODAL_CLOSE', this, () => {
                            LockManager.Instance.ReleaseLock(this.FieldModel.EntityId, recordId);
                        });

                        screen.On('RECORD_SAVED', this, (eventArgs) => {
                            this.Trigger('AGENDA_CHANGED');
                            const notifier = new Notifier();
                            notifier.Success(NOTIFICATIONS.RECORD_UPDATED);
                            this.LoadData({ groupByResource: this._groupByResource() });
                        });

                        screen.On('RECORD_REMOVED', this, () => {
                            this.Trigger('AGENDA_CHANGED');
                            this.LoadData({ groupByResource: this._groupByResource() });
                        });

                        screen.On('COPY', this, (eventArgs) => {
                            const copyTableTypeId = eventArgs.data.copyToType || tableTypeId;
                            this.NewRecord(null, copyTableTypeId, eventArgs.data.recordId, eventArgs.data.dataMode, null, null, false, null, eventArgs.data.linkToSource, eventArgs.data.aliasSuffix);
                        });

                        screen.On('FOLLOWUP_RECORD', this, (eventArgs) => {
                            const followUp = eventArgs.data.followUpRecordModel as FollowUpRecordModel;

                            if (!followUp) {
                                this.NewRecord(null, tableTypeId, eventArgs.data.recordId, DataModes.FollowUp);
                            } else if (followUp.LifeStatusSort !== null
                                && followUp.LifeStatusSort !== LIFE_STATUS_GROUPS.RETIRED
                                && !followUp.LifeStatusNoActionNode
                                && (followUp.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_CURRENT_AND_NEW
                                    || followUp.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_NEW)) {
                                this.NewRecord(null, tableTypeId, eventArgs.data.recordId, DataModes.FollowUp, followUp.LifeStatusId);
                            }
                        });

                        screen.ShowInModal();
                    })
                    .fail(err => {
                        LockManager.Instance.ReleaseLock(this.FieldModel.EntityId, recordId);
                        let notifier = new Notifier();
                        notifier.Failed(err.message);
                    });
            }).always(() => BlockUI.Unblock());
    }

    private GetLinkedUsers(linkTable: LinkTableModel[]) {
        let linkIds = [];
        const userTable = _.find(linkTable, (table) => table.Name === SYSTEM_TABLE_NAMES.SYS_USERS);
        if (userTable) {
            _.forEach(userTable.LinkRecords, (record) => {
                linkIds.push(record.Id);
            });
        }
        return linkIds;
    }

    private GetLinkedResources(linkTable: LinkTableModel[]): ILinkedResources {
        let linkedResources: ILinkedResources = {};
        _.forEach(linkTable, (table) => {
            if (table.Name !== SYSTEM_TABLE_NAMES.SYS_USERS) {
                linkedResources[table.Id] = _.map(table.LinkRecords, (record) => record.Id);
            }
        });
        return linkedResources;
    }

    private ActionToggleMenu(data): any {
        this._viewModels().forEach(item => {
            (item !== data) && item.ActionToggle(false)
        });
        data.ActionToggle(!data.ActionToggle());
    }

    private ToggleAppointmentsWeekMenu(data) {
        data.ShowAppointmentsWeekMenu(!data.ShowAppointmentsWeekMenu());
    }

    private ToggleBookingsWeekMenu(data) {
        data.ShowBookingsWeekMenu(!data.ShowBookingsWeekMenu());
    }

    private GoToScheduler(agenda, event) {
        event.stopPropagation();

        const appointment = _.find(this._tablesTypes.TableTypes, (tableType) => tableType.Name === AGENDA_TYPES.Appointment);
        const params: IRePlanningData = {
            StartTime: agenda.Event.start,
            EndTime: agenda.Event.end,
            Id: agenda.Event.id,
            LinkedRecords: {
                Links: agenda.Event.links,
                Users: this.GetLinkedUsers(agenda.Event.links),
                Resources: this.GetLinkedResources(agenda.Event.links)
            },
            Name: agenda.Event.title,
            Agenda: this,
            IsInTab: false,
            TableTypeId: appointment ? appointment.Id : null,
            LinkedSubGroups: []
        };

        if (agenda.Editable) {
            const parent = this.GetParentControl();
            if (parent && parent.GetType() === CONTROL_TYPES.TabPage && parent.GetParentControl().GetType() === CONTROL_TYPES.Tab) {
                const tabControl = parent.GetParentControl() as Tab;
                let tabWithScheduler: TabPage,
                    tabWithSchedulerIndex,
                    schedulerControlInTab: Scheduler;

                const controls = tabControl.GetAllSubControls();

                if (controls) {
                    controls.find((control, index) => {
                        if (control.GetType() === CONTROL_TYPES.TabPage) {
                            const subControls = control.GetAllSubControls();
                            if (subControls) {
                                const scheduler = subControls.find(subControl => subControl.GetType() === CONTROL_TYPES.Scheduler) as Scheduler;
                                if (scheduler) {
                                    schedulerControlInTab = scheduler;
                                    tabWithScheduler = control as TabPage;
                                    tabWithSchedulerIndex = index;
                                    return true;
                                }
                            }
                        }
                        return false;
                    });
                }

                if (schedulerControlInTab) {
                    params.IsInTab = true;
                    params.TabIndex = _.indexOf(this.GetParentControl().GetParentControl().GetAllSubControls(), this.GetParentControl());
                    tabControl.SetActiveTab(tabWithSchedulerIndex, {
                        controlId: schedulerControlInTab.GetModel().Id,
                        params: params,
                        control: schedulerControlInTab,
                        action: 'OpenReplanMode'
                    });
                } else {
                    this.ScrollToScheduler(params);
                }
            } else {
                this.ScrollToScheduler(params);
            }
        }
    }

    private ScrollToScheduler(params) {
        const allSchedulers = this.GetForm().GetScreen().GetControlsByType(CONTROL_TYPES.Scheduler);
        let scheduler = allSchedulers.find((control: Scheduler) => !control.GetParentControl()) as Scheduler;

        if (scheduler) {
            let isLoaded = false;
            $([document.documentElement, document.body]).animate({
                scrollTop: $(scheduler.GetWrapper()).offset().top - 60
            }, 1000, () => {
                if (!isLoaded) {
                    const $scheduler = $(scheduler.GetWrapper());
                    //remember the previous height to prevent control jumping
                    $scheduler.css('min-height', `${$scheduler.height()}px`).css('z-index', '1');
                    scheduler.LoadData(params);
                    isLoaded = true;
                }
            });
        } else {
            new Notifier().Warning(this._labels.SCHEDULER_NOT_FOUND);
        }
    }

    async NavigateToLinkedRecord(linkedRecord: LinkRecord, linkTable: LinkTableModel, event) {
        event.stopPropagation();

        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        BlockUI.Block();
        screenManager.GetScreenByScreenType(
            linkTable.Id,
            ScreenTypes.ConsultScreen,
            linkedRecord.Id
        )
        .always(() => BlockUI.Unblock())
        .then(screen => {
            screen.TransactionId = this.Screen.TransactionId;
            screen.ShowInModal();
        })
        .fail(err => new Notifier().Warning(err.message));
    }

    GetAppointmentDuration() {
        let appointmentDuration = GlobalManager.Instance.GetGlobal(GLOBALS.APPOINTMENT_DURATION);
        let appointmentDurationMinutes = parseInt(appointmentDuration);

        if (isNaN(appointmentDurationMinutes)) {
            appointmentDurationMinutes = DEFAULT_APPOINTMENT_DURATION;
        }

        return appointmentDurationMinutes;
    }

    GetSelectedAppointmentAttendees() {
        const appointmentAttendees = this._appointmentAttendees();
        if (!appointmentAttendees) {
            return [];
        }

        const selectedUsers = appointmentAttendees.Users.filter(user => user.Checked()).map(user => user.Id);

        const selectedUserGroups =
            appointmentAttendees.UserGroups
                .filter(userGroup => userGroup.Checked())
                .map(userGroup => userGroup.Id);

        return selectedUsers.concat(selectedUserGroups);
    }

    ReturnFromScheduler(params: IRePlanningData) {
        this._isReturned = true;
        this._selectedStartDate = params.Agenda._selectedStartDate;
        this._selectedEndDate = params.Agenda._selectedEndDate;
        this._viewRenderMode = params.Agenda._viewRenderMode;
    }

    SetStarting(starting: string) {
        this._isReturned = true;
        this._selectedStartDate = moment(starting).startOf('day').format();
        this._selectedEndDate = moment(starting).endOf('day').add(4, 'd').format();

        const $agenda = $(this.GetWrapper()).find('#agendaCalendar');
        if ($agenda.length) {
            $agenda.fullCalendar('gotoDate', this._selectedStartDate);
            $([document.documentElement, document.body]).animate({
                scrollTop: $agenda.offset().top - 60
            }, 1000)
        }

    }
    
    CustomScrollToHour(scrollToHour: number){
        const screen = this._form && this._form.GetScreen();
        if (screen.IsInModal()) {
            const $agenda = $(this.GetWrapper()).find('#agendaCalendar');
            if ($agenda.length) {
                let scroller = $agenda.find('.fc-scroller');
                if (scroller.length) {
                    const fixHour: number = 1;
                    let scrollPosition = (scrollToHour + fixHour) * 60;
                    scroller.scrollTop(scrollPosition);
                }
            }
        }
    }

    private SelectUserWillProvideEventOnLoad() {
        const screenData = this.GetForm().GetScreen().GetDataModel();

        if (screenData) {
            const screen = this._form && this._form.GetScreen();
            const selectUser = screen.GetControl(CONTROL_TYPES.SelectUser) as SelectUser;
            return selectUser ? selectUser.IsRememberMe : false;
        }

        return false;
    }

    private SelectUserIsInitialized() {
        const screenData = this.GetForm().GetScreen().GetDataModel();

        if (screenData) {
            const screen = this._form && this._form.GetScreen();
            const selectUser = screen.GetControl(CONTROL_TYPES.SelectUser) as SelectUser;
            return selectUser ? selectUser.Initialized : false;
        }

        return false
    }

    private LinkAppointmentAttendees(linkList: LinkList, calendarUserId: number) {
        const appointmentAttendees = this._appointmentAttendees();
        if (!appointmentAttendees) {
            return;
        }

        const usersRelationModel = linkList.DataModel().UserRelation();

        const selectedUsers = appointmentAttendees.Users.filter(user => user.Checked());
        selectedUsers.forEach(user => this.LinkAppointmentAttendee(user.Id, user.Name, user.UserTypeName, user.UserTypeNameTranslation, linkList, usersRelationModel));

        const selectedUserGroups = appointmentAttendees.UserGroups.filter(userGroup => userGroup.Checked());
        selectedUserGroups.forEach(userGroup => this.LinkAppointmentAttendee(userGroup.Id, userGroup.Name, userGroup.TypeName, userGroup.TypeNameTranslation, linkList, usersRelationModel));

        if (!_.any(selectedUsers) && !_.any(selectedUserGroups) && calendarUserId
            && _.any(appointmentAttendees.Users, user => user.Id === calendarUserId)) {
            const calendarUserModel = _.find(appointmentAttendees.Users, user => user.Id === calendarUserId);
            this.LinkAppointmentAttendee(calendarUserModel.Id, calendarUserModel.Name, calendarUserModel.UserTypeName, calendarUserModel.UserTypeNameTranslation, linkList, usersRelationModel);
        }
    }

    private LinkAppointmentAttendee(userId: number, userName: string, userTypeName: string, userTypeNameTranslation: string, linkList: LinkList, usersRelationModel: UserEntityRelationsViewModel) {
        const existingLink = linkList.FindLinkByRecord(userId, usersRelationModel);
        if (existingLink) {
            return;
        }

        let newRelation = new NewRelationModel();
        newRelation.Name = userName;
        newRelation.Id = userId;
        newRelation.IsMain = false;
        newRelation.Level = SECURITY_LEVELS.SHARED;
        newRelation.TypeName = userTypeName;
        newRelation.TypeTranslatedName = userTypeNameTranslation;

        linkList.AddRecord(userId, usersRelationModel, newRelation);
    }

    AfterRender(el: Array<HTMLElement>): void {
        super.AfterRender(el);
        ko.utils.domNodeDisposal.addDisposeCallback(this._el, ()=> {
            SignalRNotificationManager.Instance.Off('UPDATE_AGENDA', this);
        });
    }
}